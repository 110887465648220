import { ChangeDetectionStrategy, Component, Inject, input, output } from '@angular/core';
import { DocumentFile } from '@trade-platform/ui-shared';
import { Router } from '@angular/router';
import { fileType } from '@trade-platform/lib-enums';
import { isDisabledFile } from '../../../utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    AixDataTestingDirective,
    AixLoadingButtonComponent,
    AixTooltipDirective
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-document-list',
    templateUrl: 'document-list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        AixDataTestingDirective,
        NgClass,
        NgIf,
        AixLoadingButtonComponent,
        AsyncPipe,
        AixTooltipDirective
    ]
})
export class AixDocumentListComponent {
    documentsToDisplay = input<DocumentFile[]>();
    currentDocument = input<string>();

    // Offset to select items by index when we have multiple document lists
    offset = input<number>(0);

    clickDownload = output<string>();

    fileType = fileType;
    titleLoading: string;
    fileLoading: string;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private router: Router
    ) {}

    download(fileId: string) {
        this.clickDownload.emit(fileId);

        const document = this.documentsToDisplay()?.find(document => document.id === fileId);
        this.titleLoading = document?.screenName ?? document?.name ?? '';
        this.fileLoading = fileId;
    }

    isDisabledFile(file: DocumentFile) {
        return isDisabledFile(file);
    }

    activateDocument(fileId: string) {
        const order = this.storeFacade.order;
        const document = order.files.find(file => file.id === fileId);
        if (!document || !this.isDisabledFile(document)) {
            this.router.navigate(this.storeFacade.routes.documentViewer(order.id, fileId));
        }
    }
}
