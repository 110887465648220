import {
    Component,
    forwardRef,
    input,
    OnChanges,
    OnDestroy,
    output,
    SimpleChanges
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Product } from '@trade-platform/ui-shared';
import { isArray } from 'lodash-es';
import { NgIf } from '@angular/common';
import {
    AixDataTestingDirective,
    AixDropdownComponent,
    AixNotificationComponent
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-product',
    templateUrl: './product.html',
    styleUrls: ['./product.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixProductGroupComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixProductGroupComponent
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        AixNotificationComponent,
        AixDataTestingDirective,
        AixDropdownComponent
    ]
})
export class AixProductGroupComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    shareClass = input<Product | undefined>();
    startOrder = input<any>();
    activeInvestorError = input<boolean>(false);

    onShareClassSelect = output<number>();

    form: UntypedFormGroup;
    availableOptions: any[] = [];
    firstShareClass: any;

    subscriptions: Subscription[] = [];

    private _onChange: (value: any | null | undefined) => void;
    private _destroy$: Subject<void> = new Subject<void>();

    constructor(private _fb: UntypedFormBuilder) {
        this._createFormGroup();
        this._setupObservables();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.startOrder()) {
            this.patchForm();
        }
    }

    patchForm() {
        this.availableOptions = this.startOrder().product.shareClass;
        this.firstShareClass = this.availableOptions[0];

        const data = {
            productLabel: `${this.startOrder().product.fundName}`,
            shareClassSelected:
                this.shareClass() || (!this.shareClass() && this.availableOptions.length > 1)
                    ? this.shareClass()
                    : this.firstShareClass
        };

        this.onSelectShareClass(data.shareClassSelected);

        this.form.patchValue(data);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    validate(ctrl: UntypedFormControl) {
        return this.form.valid ? null : { invalid: true };
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            return;
        }
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    onSelectShareClass(shareClass: any) {
        if (isArray(shareClass)) {
            if (shareClass.length) {
                this.onShareClassSelect.emit(shareClass[0].id);
            }
        } else if (shareClass) {
            this.onShareClassSelect.emit(shareClass.id);
        }
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            productLabel: this._fb.control('', [Validators.required]),
            shareClassSelected: this._fb.control('', [Validators.required])
        });
    }

    private _setupObservables() {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
