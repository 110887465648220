import {
    ChangeDetectionStrategy,
    Component,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import {
    AixCheckboxItemQueue,
    AixCheckboxListComponent
} from '../aix-checkbox-list/aix-checkbox-list';
import { NgIf } from '@angular/common';
import { AixShowMoreComponent } from '../aix-show-more/aix-show-more';

@Component({
    selector: 'aix-checkbox-panel',
    templateUrl: './aix-checkbox-panel.html',
    styleUrls: ['./aix-checkbox-panel.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AixCheckboxListComponent, AixDataTestingDirective, AixShowMoreComponent]
})
export class AixCheckboxPanelComponent implements OnChanges {
    title = input<string>();
    index = input<number>();
    filters = input.required<AixCheckboxItemQueue[]>();

    activeFilter = model.required<AixCheckboxItemQueue[]>();

    showDivider = input(true);
    showCheckbox = input(false);
    length = input<number>();
    numberShowList = input(7);

    onFilter = output<AixCheckboxItemQueue | AixCheckboxItemQueue[]>();

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters) {
            this.filters().sort((a, b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
    }

    clickFilter(item: AixCheckboxItemQueue[]) {
        this.activeFilter.set(item);
        this.onFilter.emit(item);
    }
}
