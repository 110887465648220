import {
    ChangeDetectorRef,
    Component,
    Inject,
    input,
    Input,
    OnDestroy,
    OnInit,
    output
} from '@angular/core';
import {
    AbstractControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup
} from '@angular/forms';
import { constants, DocumentFile, SupplementalFile } from '@trade-platform/ui-shared';
import { Subscription } from 'rxjs';
import { getOwners, getUploadedSupplementalFiles } from '../../process/overview/utils/order-utils';
import { DocumentOwner } from '../filelist/filelist';
import {
    AixButtonComponent,
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixDropdownComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { RemoteDataModule } from 'ngx-remotedata';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { profileHasRoles, ProfileStoreFacade } from '@advisor-ui/app-services';

export interface UploadedFileRelation {
    owner?: DocumentOwner | null;
    supplementalFileTypeId?: SupplementalFile | null;
    isPrivate?: boolean;
}

@Component({
    selector: 'aix-label-uploaded-file-documents',
    templateUrl: './label-uploaded-file-documents.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        NgIf,
        AixDataTestingDirective,
        AixDropdownComponent,
        AixButtonComponent,
        AsyncPipe,
        RemoteDataModule,
        AixCheckboxComponent
    ]
})
export class AixLabelUploadedFileDocumentsComponent implements OnInit, OnDestroy {
    documentTypes = input<SupplementalFile[]>();
    filterBy = input<string>();

    filesUpdated = output();
    dismissModal = output();

    relationFormReady = false;
    relationForm: UntypedFormGroup;
    relationsControls: AbstractControl[] = [];
    supplementalFiles: DocumentFile[] = [];
    subscriptions: Subscription[] = [];
    owners: DocumentOwner[];
    formIsInvalid = true;
    cancelButtonType = BUTTON_TYPE.link;
    saveButtonType = BUTTON_TYPE.primary;

    enablePrivate = false;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private profileStore: ProfileStoreFacade,
        private cd: ChangeDetectorRef,
        private _fb: UntypedFormBuilder
    ) {
        this.relationForm = this._fb.group({
            relations: this._fb.array([])
        });
    }

    ngOnInit() {
        this.enablePrivate =
            profileHasRoles(
                [{ name: constants.profileTypes.REVIEWER }],
                this.profileStore.profile
            ) && window.location.href.includes('/detail/');

        this.subscriptions.push(
            this.store.actions.updateOrderFiles.success$.subscribe(action => {
                this.filesUpdated.emit();
            }),
            this.store.orderSuccess$.subscribe(order => {
                this.owners = getOwners(order);

                const relations: UploadedFileRelation[] = [];
                (this.relationForm.get('relations') as UntypedFormArray).controls = []; // clear existing relations controls before creating new ones;
                this.supplementalFiles = getUploadedSupplementalFiles(order).map(item => item.file);
                this.supplementalFiles.forEach(f => {
                    // Show "is private" checkbox only to non-required uploads
                    const order = this.store.order;
                    f.isRequired = !!order.requiredSupplementalFiles.find(
                        r => r.id === f.supplementalFileTypeId
                    );

                    const control = this.relationForm.controls['relations'] as UntypedFormArray;
                    control.push(
                        this._fb.group({
                            owner: '',
                            supplementalFileTypeId: '',
                            isPrivate: false
                        })
                    );

                    if (f.supplementalFileTypeId || f.contextMap) {
                        relations.push({
                            owner: this.owners.find(i => i.contextMap === f.contextMap),
                            supplementalFileTypeId: this.documentTypes()?.find(
                                d => d.id === f.supplementalFileTypeId
                            ),
                            isPrivate: this.enablePrivate ? f.isPrivate : false
                        });
                    } else {
                        relations.push({
                            owner: null,
                            supplementalFileTypeId: null,
                            isPrivate: false
                        });
                    }
                });

                this.relationForm.patchValue({
                    relations: relations
                });

                this.relationsControls = (
                    this.relationForm.get('relations') as UntypedFormArray
                ).controls;
                this.relationFormReady = true;

                this.cd.detectChanges();
            }),
            this.relationForm.valueChanges.subscribe(value => {
                this.formIsInvalid = this.isInvalid();
            })
        );
    }

    cancelFileUpload() {
        this.dismissModal.emit();
    }

    clickSaveRelations() {
        const order = this.store.order;
        const relations = this.relationForm.value.relations;
        const updatedSupplementalFiles: DocumentFile[] = [];
        this.supplementalFiles.forEach((file, index) => {
            if (relations[index].owner && relations[index].supplementalFileTypeId) {
                const owner = relations[index].owner.length
                    ? relations[index].owner[0]
                    : relations[index].owner;
                const doc = relations[index].supplementalFileTypeId.length
                    ? relations[index].supplementalFileTypeId[0]
                    : relations[index].supplementalFileTypeId;
                const isPrivate = this.enablePrivate ? relations[index].isPrivate : false;

                // only update files that have been relabelled
                if (
                    file.contextMap !== owner.contextMap ||
                    !!file.isPrivate !== !!isPrivate ||
                    doc.id !== file.supplementalFileTypeId
                ) {
                    updatedSupplementalFiles.push({
                        ...file,
                        contextMap: owner.contextMap,
                        isPrivate: isPrivate,
                        supplementalFileTypeId: doc.id
                    });
                }

                this.supplementalFiles[index].contextMap = owner.contextMap;
                this.supplementalFiles[index].supplementalFileTypeId = doc.id;
                this.supplementalFiles[index].isPrivate = isPrivate;
            }
        });

        this.store.actions.updateOrderFiles.dispatch({
            updatePayload: updatedSupplementalFiles,
            updatePayloadExtras: { orderId: order.id },
            reducerSuffix: 'orderFilesUpdate'
        });
    }

    isInvalid(): boolean {
        let invalid = false;

        if (!this.supplementalFiles.length) {
            return invalid;
        }

        this.relationForm.value.relations
            .filter((item: UploadedFileRelation, index: number) => {
                return this.filterBy()
                    ? this.supplementalFiles[index]?.id === this.filterBy()
                    : !this.supplementalFiles[index]?.contextMap &&
                          !this.supplementalFiles[index]?.supplementalFileTypeId;
            })
            .forEach((item: UploadedFileRelation) => {
                if (
                    (Array.isArray(item.owner) && item.owner.length === 0) ||
                    !item.owner ||
                    (Array.isArray(item.supplementalFileTypeId) &&
                        item.supplementalFileTypeId.length === 0) ||
                    !item.supplementalFileTypeId
                ) {
                    invalid = true;
                }
            });

        return invalid;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
