import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    input,
    model,
    OnChanges,
    output,
    OutputEmitterRef,
    signal,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixRadioGroupComponent } from '../aix-radio-group/aix-radio-group.component';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { AixDateComponent } from '../aix-date/aix-date.component';
import {
    dateRangeValidatorFactory,
    formatUTCDateString,
    validateMaxDateFactory
} from '@trade-platform/ui-utils';
import { DatePipe, JsonPipe } from '@angular/common';

@Component({
    selector: 'aix-date-filter',
    templateUrl: './aix-date-filter.component.html',
    styleUrls: ['./aix-date-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AixButtonComponent,
        AixDataTestingDirective,
        AixRadioGroupComponent,
        FormsModule,
        ReactiveFormsModule,
        AixDateComponent,
        JsonPipe,
        DatePipe
    ]
})
export class AixDateFilterComponent implements OnChanges {
    isClearingFilter = false;
    label = input.required();
    filters = model<{ fromCreatedDate: string; toCreatedDate: string; type: string }>();
    isFilterOpen = signal(false);
    applyFilterButtonEnabled = signal(false);
    hasFilter = false;
    showTooltip = false;
    tooltipBeginDate = '';
    tooltipEndDate = '';
    currentFilters: any = {
        type: 'allTime',
        beginDate: '',
        endDate: ''
    };

    applyFilters: OutputEmitterRef<{
        fromCreatedDate: string;
        toCreatedDate: string;
        type: string;
    }> = output();

    @HostListener('document:click', ['$event'])
    onClickOutside($event: MouseEvent) {
        if (!this.ref.nativeElement.contains($event.target)) {
            if (this.currentFilters.type !== this.filters()?.type) {
                this.filters.set(this.currentFilters);
                this.hasFilter = false;
            }
            if (this.currentFilters.type !== this.dateFilterForm.controls['dateFilter'].value) {
                this.dateFilterForm.controls['dateFilter'].setValue(this.currentFilters.type);
                this.dateFilterForm.controls['beginDate'].setValue(this.currentFilters.beginDate);
                this.dateFilterForm.controls['endDate'].setValue(this.currentFilters.endDate);
            }
            if (this.currentFilters.type && this.currentFilters.type !== 'allTime') {
                this.hasFilter = true;
            }
            this.onSelectDateFilter(this.currentFilters.type);
            this.isFilterOpen.set(false);
        }
    }

    dateFilterForm: UntypedFormGroup;

    dateFilterOptions = [
        { value: 'allTime', title: 'All Time' },
        { value: 'thisMonth', title: 'This Month' },
        { value: 'thisYear', title: 'This Year' },
        { value: 'custom', title: 'Custom' }
    ];

    setFilterButtonType = BUTTON_TYPE.primary;
    clearFilterButtonType = BUTTON_TYPE.link;

    constructor(private cd: ChangeDetectorRef, private ref: ElementRef) {
        this.dateFilterForm = new UntypedFormGroup({
            dateFilter: new UntypedFormControl('allTime', Validators.required),
            beginDate: new UntypedFormControl('', [
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate')
            ]),
            endDate: new UntypedFormControl('', [
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate')
            ])
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters) {
            if (
                this.filters() &&
                this.currentFilters.type !== this.filters()?.type &&
                this.currentFilters.toCreatedDate !== this.filters()?.toCreatedDate &&
                this.currentFilters.fromCreatedDate !== this.filters()?.fromCreatedDate
            ) {
                this.dateFilterForm = new UntypedFormGroup({
                    dateFilter: new UntypedFormControl(this.filters()?.type, Validators.required),
                    beginDate: new UntypedFormControl(this.filters()?.fromCreatedDate, [
                        dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate')
                    ]),
                    endDate: new UntypedFormControl(this.filters()?.toCreatedDate, [
                        dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate')
                    ])
                });
                this.currentFilters = this.filters();
                this.hasFilter = this.currentFilters.type !== 'allTime';
                this.dateFilterForm.controls.dateFilter.markAsDirty();
            } else if (this.filters()?.type === 'allTime') {
                this.hasFilter = false;
            }
        }
    }

    updateDateValidation() {
        setTimeout(() => {
            this.dateFilterForm.controls.beginDate.updateValueAndValidity();
            this.dateFilterForm.controls.endDate.updateValueAndValidity();
            this.dateFilterForm.updateValueAndValidity();
            this.cd.detectChanges();
        }, 1000);
    }

    formatDate(date: Date): string {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    showDropdown() {
        if (!this.isClearingFilter) {
            this.showTooltip = false;
            this.isFilterOpen.set(!this.isFilterOpen());
        }
        this.isClearingFilter = false;
    }

    onEnterDateFilter() {
        if (!this.isFilterOpen()) {
            this.showTooltip = true;
            if (this.dateFilterForm.get('beginDate')?.value) {
                this.tooltipBeginDate = formatUTCDateString(
                    this.dateFilterForm.get('beginDate')?.value,
                    'mmddyyyy'
                );
            }
            if (this.dateFilterForm.get('endDate')?.value) {
                this.tooltipEndDate = formatUTCDateString(
                    this.dateFilterForm.get('endDate')?.value,
                    'mmddyyyy'
                );
            }
        }
    }

    onLeaveDateFilter() {
        this.showTooltip = false;
        this.tooltipBeginDate = '';
        this.tooltipEndDate = '';
    }

    onSelectDateFilter(evt: any) {
        this.applyFilterButtonEnabled.set(true);

        const beginDate = this.dateFilterForm.get('beginDate');
        const endDate = this.dateFilterForm.get('endDate');

        if (evt === 'custom') {
            beginDate?.setValidators([
                Validators.required,
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate'),
                validateMaxDateFactory('MMddyyyy', 'maxDate(MMddyyyy).currentDate')
            ]);
            endDate?.setValidators([
                Validators.required,
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate'),
                validateMaxDateFactory('MMddyyyy', 'maxDate(MMddyyyy).currentDate')
            ]);
        } else {
            switch (evt) {
                case 'allTime':
                    beginDate?.setValue('');
                    endDate?.setValue('');
                    break;
                case 'thisMonth':
                    const firtDayOfThisMonth = new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                    );
                    beginDate?.setValue(this.formatDate(firtDayOfThisMonth));
                    endDate?.setValue(this.formatDate(new Date()));
                    break;
                case 'thisYear':
                    const firtDayOfYear = new Date(new Date().getFullYear(), 0, 1);
                    beginDate?.setValue(this.formatDate(firtDayOfYear));
                    endDate?.setValue(this.formatDate(new Date()));
                    break;
            }

            beginDate?.setValidators([
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate'),
                validateMaxDateFactory('MMddyyyy', 'maxDate(MMddyyyy).currentDate')
            ]);
            endDate?.setValidators([
                dateRangeValidatorFactory(this.dateFilterForm, 'beginDate', 'endDate'),
                validateMaxDateFactory('MMddyyyy', 'maxDate(MMddyyyy).currentDate')
            ]);
        }

        beginDate?.updateValueAndValidity();
        endDate?.updateValueAndValidity();
        this.dateFilterForm.updateValueAndValidity();
        this.cd.detectChanges();
    }

    setFilter() {
        const newFilters = {
            fromCreatedDate: this.dateFilterForm.controls['beginDate'].value,
            toCreatedDate: this.dateFilterForm.controls['endDate'].value,
            type: this.dateFilterForm.controls['dateFilter'].value
        };

        this.currentFilters = newFilters;
        this.applyFilters.emit(newFilters);
        this.filters.set(newFilters);
        this.hasFilter = newFilters.type !== 'allTime';
        this.cd.detectChanges();
    }

    onClickClearFilterIcon() {
        this.isClearingFilter = true;
        this.clearFilter();
    }

    clearFilter() {
        this.onSelectDateFilter('allTime');
        this.dateFilterForm.controls['dateFilter'].setValue('allTime');
        this.dateFilterForm.controls['dateFilter'].markAsPristine();
        this.filters.set({
            fromCreatedDate: this.dateFilterForm.controls['beginDate'].value,
            toCreatedDate: this.dateFilterForm.controls['endDate'].value,
            type: 'allTime'
        });
        this.currentFilters = this.filters();
        this.hasFilter = this.filters()?.type !== 'allTime';
        this.applyFilters.emit({
            fromCreatedDate: '',
            toCreatedDate: '',
            type: 'allTime'
        });
    }
}
