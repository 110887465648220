<label
    class="checkbox-custom checkbox-custom-label-size"
    [class.aix-disabled]="isDisabled()"
    [data-testing]="config()?.refId! + '__label'"
    [ngClass]="bodyClassNames()"
>
    <input
        #inputField
        type="checkbox"
        [data-testing]="config()?.refId!"
        [checked]="valueToRender()"
        (change)="onUserInput($event)"
        [required]="isRequired()"
        [class.aix-disabled]="isDisabled()"
        [disabled]="isDisabled()"
        [attr.tabindex]="isDisabled() ? '-1' : ''"
    />
    <span
        class="checkbox-custom__indicator"
        [class.aix-form__control--dirty]="isDirty()"
        [class.aix-form__control--invalid]="!isValid()"
        [class.aix-form__control--invalid-required]="isRequired() && !inputField.checked"
    >
    </span>
    <span [ngClass]="headerClassNames()" [class.checkbox-custom__label]="!isValid() && isDirty()">
        <ng-content></ng-content>
        @if (description()) {
        <span class="checkbox-custom__sublabel">{{ description() }}</span>
        }
    </span>
    @if (infoTooltip()) {
    <span
        aixTooltip
        [aixTooltipContent]="infoTooltip()"
        [data-testing]="config()?.refId! + '__infotooltip'"
    ></span>
    }
</label>
