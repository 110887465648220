@if (activeTab()) {
<div class="aix-viewer__header">
    @for (tab of tabs(); track tab) {
    <div
        class="aix-viewer__header-title br-primary__color"
        [attr.data-testing]="tab?.toLowerCase()"
        (click)="onClickTab.emit(tab)"
        [ngClass]="{
            'br-primary__border-color': activeTab() === tab,
            inactive: activeTab() !== tab
        }"
    >
        {{ tab }}
    </div>
    }
</div>
} @if (activeTab()) {
<div class="aix-viewer__content">
    @for (tab of tabs(); track tab) {
    <section class="-no-padding" [hidden]="activeTab() !== tab">
        @if (tab === activeTab()) {
        <div class="aix-viewer__body-content">
            <ng-content [select]="tab.toLowerCase()"></ng-content>
        </div>
        }
    </section>
    }
</div>
}
