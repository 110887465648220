import { JsonDecoder } from 'ts.data.json';
import { OrderFormComment } from '../../models/order-comments/model';

export const orderFormCommentDecoder = JsonDecoder.object<OrderFormComment>(
    {
        id: JsonDecoder.number,
        orderId: JsonDecoder.string,
        userId: JsonDecoder.string,
        documentId: JsonDecoder.optional(JsonDecoder.string),
        documentName: JsonDecoder.optional(JsonDecoder.string),
        commentType: JsonDecoder.string,
        commentPrivate: JsonDecoder.optional(JsonDecoder.boolean),
        organizationId: JsonDecoder.number,
        organizationName: JsonDecoder.string,
        text: JsonDecoder.string,
        status: JsonDecoder.string,
        readOnly: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        date: JsonDecoder.optional(JsonDecoder.string),
        time: JsonDecoder.optional(JsonDecoder.string),
        updatedAt: JsonDecoder.optional(JsonDecoder.string),
        isOnForm: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        commentTag: JsonDecoder.optional(JsonDecoder.string),
        parentId: JsonDecoder.optional(JsonDecoder.number),
        children: JsonDecoder.oneOf<OrderFormComment[]>(
            [JsonDecoder.lazy(() => orderFormCommentsDecoder), JsonDecoder.isUndefined([])],
            'OrderFormComment[]'
        )
    },
    'OrderFormComment'
);

export const orderFormCommentsDecoder: JsonDecoder.Decoder<OrderFormComment[]> =
    JsonDecoder.array<OrderFormComment>(orderFormCommentDecoder, 'OrderFormComment[]');
