import { Component, input, output } from '@angular/core';
import { NgClass, NgFor } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-ui-document-list',
    templateUrl: './document-list.component.html',
    standalone: true,
    imports: [NgFor, AixDataTestingDirective, NgClass]
})
export class AixUIDocumentListComponent {
    documents = input<any[]>();
    currentDocument = input<string>('');

    onActivateDocument = output<string>();

    constructor() {}
}
