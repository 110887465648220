<div class="aix-viewer__action-bar aix-toolbar" [data-testing]="'action-bar'">
    <div class="aix-viewer__action-bar__container--left">
        <ng-content select="[left]"></ng-content>
    </div>

    <div
        class="aix-viewer__action-bar__container--right"
        [ngClass]="{ 'u-text-right': isOffline() }"
    >
        <ng-content select="[right]"></ng-content>
    </div>
</div>
