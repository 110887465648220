import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    viewChild,
    ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AixSanitizePipe, fromObservable, objectHasValue } from '@trade-platform/ui-utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { getControlDataBinding } from '../../dynamic-form-store/utils';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { ExpandableTextFieldConfig } from '@trade-platform/form-fields';
import { Field } from '../field.interface';
import { caseOf, withDefault } from 'ts.data.maybe';
import { DynamicFormStore } from '../../dynamic-form-store';
import { delay, filter } from 'rxjs/operators';
import { AixDataTestingDirective } from '@trade-platform/ui-components';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-dynamic-expandable-text',
    styleUrls: ['./expandable-text.component.scss'],
    templateUrl: './expandable-text.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AixDataTestingDirective, AsyncPipe, AixSanitizePipe]
})
export class AixDynamicExpandableTextComponent implements Field, OnInit, OnDestroy {
    // Static
    static HOST_CLASS = 'aix-flex-grid';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicExpandableTextComponent.HOST_CLASS;

    textContainerRef = viewChild.required<ElementRef>('textContainerRef');

    // Store
    valueToRender$ = new BehaviorSubject('');

    // Other
    config: ExpandableTextFieldConfig;
    subscriptions: Subscription[] = [];
    linkUnlisteners: Array<() => void> = [];
    expanded = false;

    constructor(
        private helper: DynamicFormHelper,
        private store: Store<Record<string, DynamicFormState>>,
        private formStore: DynamicFormStore,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        this.cd.detach();
    }

    calculateClassNames() {
        this.classNames = this.config.classNames
            ? [
                  AixDynamicExpandableTextComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicExpandableTextComponent.HOST_CLASS;
    }

    registerLinkEventListeners() {
        this.linkUnlisteners.forEach(unlisten => unlisten());
        const span = this.textContainerRef().nativeElement as HTMLSpanElement;
        const links = span.querySelectorAll('a[data-action]');
        links.forEach(link => {
            this.renderer.listen(link, 'click', event => {
                const a: HTMLAnchorElement = event.target;
                const action = a.getAttribute('data-action');
                const actionValue = a.getAttribute('data-action-value');
                if (action !== null) {
                    this.store.dispatch(this.helper.actionFactory[action](actionValue));
                }
            });
        });
    }

    ngOnInit() {
        this.calculateClassNames();

        // Control initialization
        this.formStore.addNonControl(this.config);

        const { value$ } = getControlDataBinding(
            this.store,
            this.config,
            this.helper.observableMapFuncts
        );

        caseOf(
            {
                Just: data$ => {
                    const boundValue = fromObservable(withDefault(value$, null));
                    this.valueToRender$.next(objectHasValue(boundValue) ? boundValue : '');
                    // late binding subscription
                    this.subscriptions.push(
                        data$.pipe(filter(data => !!data)).subscribe(data => {
                            this.valueToRender$.next(data);
                            this.cd.detectChanges();
                        })
                    );
                },
                Nothing: () => this.valueToRender$.next(this.config.text)
            },
            value$
        );

        this.subscriptions.push(
            this.valueToRender$.pipe(delay(100)).subscribe(() => {
                this.registerLinkEventListeners();
            })
        );

        this.cd.detectChanges();
    }

    toggle() {
        this.expanded = !this.expanded;
        (document?.activeElement as HTMLElement).blur();
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.linkUnlisteners.forEach(unlisten => unlisten());
        this.formStore.removeControl(this.config);
    }
}
