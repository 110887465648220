import {
    ComponentRef,
    Directive,
    input,
    OnChanges,
    OnInit,
    SimpleChanges,
    Type,
    ViewContainerRef
} from '@angular/core';
import { DefaultDropdownCellrendererComponent } from './default-dropdown.cellrenderer';
import { WrappedOption } from './dropdown';

export interface DropdownCellrenderer {
    option: WrappedOption;
    setOption(option: WrappedOption): void;
}

@Directive({
    selector: '[aixDropdownCellrenderer]',
    standalone: true
})
export class DropdownCellrendererDirective implements OnInit, OnChanges {
    option = input<WrappedOption>();
    cellRenderer = input<Type<DropdownCellrenderer>>();

    component: ComponentRef<DropdownCellrenderer>;

    constructor(private container: ViewContainerRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.component && changes.option) {
            this.component.instance.setOption(<WrappedOption>this.option());
        }
    }

    ngOnInit() {
        const cellRenderer = this.cellRenderer() || DefaultDropdownCellrendererComponent;
        this.component = this.container.createComponent(cellRenderer);
        this.component.instance.setOption(<WrappedOption>this.option());
    }
}
