<form [formGroup]="form">
    <div class="header--section">
        <div class="-heading">
            <h3>Advisor Information</h3>
        </div>
    </div>

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #firmDropdownRef
                class="aix-form__control--required"
                [class.aix-form__control--dirty]="firmDropdownRef.isDirty"
                [class.aix-form__control--invalid-required]="(form.value.firm?.length >= 0 ?
                        !form.value.firm?.length :
                        !form.value.firm)"
                [class.aix-form__control--invalid]="(form.value.firm?.length >= 0 ?
                        !form.value.firm?.length :
                        !form.value.firm)"
                [aixPendingField]="{ control: form.get('firm') }"
                [formControlName]="'firm'"
                [options]="firms()"
                [labelStrategy]="firmLabelStrategy"
                labelField="name"
                valueField="id"
                placeholder=""
                [data-testing]="'firm'"
                (ngModelChange)="selectFirm($event[0])"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Firm Name</label>
        </div>
    </div>
    @if (form.value.firm) {
    <div
        [hidden]="(form.value.firm.length === 1 && (form.value.firm[0].type === 'RIA' || form.value.firm[0].type === 'Broker Dealer' || form.value.firm[0].type === 'Insurance')) || !form.value.firm.length"
    >
        <aix-radio-group
            formControlName="firmType"
            [aixPendingField]="{ control: form.get('firmType') }"
            [options]="advisorTypes"
            [title]="'Please note how you will be submitting this business.'"
            [labelField]="'title'"
            [valueField]="'value'"
            [isValid]="!!form.get('firmType')?.valid"
            [isRequired]="true"
        >
        </aix-radio-group>
    </div>
    }

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #advisorDropdownRef
                class="aix-form__control--required"
                [class.aix-form__control--dirty]="advisorDropdownRef.isDirty"
                [class.aix-form__control--invalid-required]="(form.value.repCode?.length >= 0 ?
                        !form.value.repCode?.length :
                        !form.value.repCode)"
                [class.aix-form__control--invalid]="(form.value.repCode?.length >= 0 ?
                        !form.value.repCode?.length :
                        !form.value.repCode)"
                [aixPendingField]="{ control: form.get('repCode') }"
                [formControlName]="'repCode'"
                labelField="listName"
                valueField="id"
                placeholder=""
                [data-testing]="'advisor'"
                [options]="startOrder()?.repCodes"
                (ngModelChange)="selectRepCode($event[0])"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Choose a Rep ID</label>
        </div>
    </div>

    <div class="aix-flex-grid__col">
        <div class="aix-form__group">
            <aix-dropdown
                #solicitingAdvisorRef
                class="aix-form__control--required"
                [class.aix-form__control--dirty]="solicitingAdvisorRef.isDirty"
                [class.aix-form__control--invalid-required]="(form.value.advisor?.length >= 0 ?
                        !form.value.advisor?.length :
                        !form.value.advisor)"
                [class.aix-form__control--invalid]="(form.value.advisor?.length >= 0 ?
                        !form.value.advisor?.length :
                        !form.value.advisor)"
                [aixPendingField]="{ control: form.get('advisor') }"
                [formControlName]="'advisor'"
                labelField="displayName"
                valueField="id"
                placeholder=""
                [data-testing]="'solicitingAdvisor'"
                [options]="advisors()"
                (ngModelChange)="selectAdvisor($event[0])"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Choose Soliciting Advisor</label>
        </div>
    </div>
</form>
