<div class="product-item__container">
    <div class="product-item">
        <div class="product-item__content">
            <div class="product-item__title">{{ product().name }}</div>

            <div class="product-item__logo">
                <img
                    class="logo"
                    [src]="product().exploreLogoUrl"
                    onerror="this.style.display='none'"
                />
            </div>

            <div class="flex-right-center">
                <aix-button
                    [buttonType]="viewMoreButtonType"
                    (click)="onCardClick(product())"
                    [data-testing]="'buy'"
                    [buttonLabel]="'View More'"
                ></aix-button>
            </div>
        </div>
    </div>
</div>
