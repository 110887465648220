@if (type() === 'linear') {
<div class="aix-progress" [ngStyle]="{height: strokeWidth() + 'px'}">
    <div
        class="aix-progress__bar"
        [ngStyle]="{width: percentage + '%', height: strokeWidth() + 'px'}"
    ></div>
</div>
} @if (type() === 'circular') {
<svg [attr.width]="sqSize()" [attr.height]="sqSize()" [attr.viewBox]="viewBox">
    <circle
        class="circle-background"
        [attr.cx]="sqSize() / 2"
        [attr.cy]="sqSize() / 2"
        [attr.stroke-width]="innerStrokeWidth + 'px'"
        [attr.r]="radius"
    ></circle>

    <circle
        class="circle-progress br-primary__stroke"
        [ngClass]="strokeColor() || ''"
        [attr.cx]="sqSize() / 2"
        [attr.cy]="sqSize() / 2"
        [attr.stroke-linecap]="strokeLinecap()"
        [attr.stroke-width]="strokeWidth() + 'px'"
        [attr.transform]="'rotate(-90 ' + sqSize() / 2 + ' ' + sqSize() / 2 + ')'"
        [attr.r]="radius"
        [ngStyle]="{ 'stroke-dasharray': dashArray, 'stroke-dashoffset': dashOffset ? dashOffset : 0 }"
    ></circle>

    @if (maxN() !== progressN()) {
    <text
        class="circle-text"
        [data-testing]="'step-progress-text'"
        [attr.x]="stepX"
        [attr.y]="stepY"
        text-anchor="middle"
        [ngStyle]="{'font-size': fontSize() / 4.1667 + 'em'}"
    >
        <tspan class="circle-text-number">
            @if (showTotal()) {
            <tspan>{{ progressN() }}/{{ maxN() }}</tspan>
            } @if (!showTotal()) {
            <tspan>{{ maxN() - progressN() }}</tspan>
            }
        </tspan>
    </text>
    } @if (maxN() === progressN()) {
    <text
        class="circle-text"
        [data-testing]="'step-progress-check'"
        [attr.x]="stepX"
        [attr.y]="stepY"
        text-anchor="middle"
        [ngStyle]="{'font-size': fontSize() / 4.1667 + 'em'}"
    >
        <tspan class="circle-text-check br-primary__fill">&#xf00c;</tspan>
    </text>
    } @if (subtext()) {
    <text
        class="circle-text-complete"
        [data-testing]="'step-progress-text-complete'"
        x="50%"
        [attr.y]="(sqSize() / 2) + (fontSize() * 2)"
        text-anchor="middle"
        [ngStyle]="{'font-size': fontSize() / 10 + 'em'}"
    >
        @for (l of wordWrapText(); track l; let i = $index) {
        <tspan x="50%" [attr.dy]="i + 'em'">{{ l }}</tspan>
        }
    </text>
    }
</svg>
}
