import { formatCurrency } from '@angular/common';
import { entityType, orderStatus, orderStatusIndex } from '@trade-platform/lib-enums';
import {
    DocumentFile,
    OrderCurrentApproval,
    Profile,
    ProfileRepCode,
    Role
} from '@trade-platform/ui-shared';
import { ApiFormErrorItem, objectHasValue } from '@trade-platform/ui-utils';

type Steps = typeof orderStatusIndex;

export const userHasOrderRepcode = (profile: Profile, orderRepCode: ProfileRepCode) => {
    return profile.repCodes?.some(repCode => repCode.id === orderRepCode.id);
};

export const isAdminAndCanEditOrder = (profile: Profile, orderFirmId: string) => {
    const isAdmin = profile.roles.some(role => role.name === 'admin');
    const isInOrderFirm = profile.firm && profile.firm.id === orderFirmId;
    const isInOrderFirmParent =
        profile.organization &&
        profile.organization.subTenants &&
        !!profile.organization.subTenants.find(
            subTenant =>
                subTenant.type === entityType.firm && subTenant.entityId.toString() === orderFirmId
        );
    return isAdmin && (isInOrderFirm || isInOrderFirmParent);
};

export const isFundSponsorAdmin = (profile: Profile) => {
    const isAdmin = profile.roles.some(role => role.name === 'admin');
    const isFundSponsor = !!profile.fundSponsor?.id && !profile.firm?.id;

    return isAdmin && isFundSponsor;
};

export const isHoldingOptionAdmin = (profile: Profile) => {
    const isAdmin = profile.roles.some(role => role.name === 'admin');
    const isHoldingOption = !!profile.holdingOption?.id && !profile.firm?.id;

    return isAdmin && isHoldingOption;
};

export const isReviewer = (profile: Profile) => {
    return profile.roles.some(role => role.name === 'reviewer');
};

export const hasReadOnlyAccess = (profile: Profile, orderFirmId: string) => {
    const isAdminAndCanEditOrderValue = isAdminAndCanEditOrder(profile, orderFirmId);
    const isFundSponsorAdminValue = isFundSponsorAdmin(profile);
    const isHoldingOptionAdminValue = isHoldingOptionAdmin(profile);
    const isReviewerValue = isReviewer(profile);

    // is advisor/investor/assistant
    const isOrderEditor = profile.roles.some(
        role =>
            role.name === 'advisor' || role.name === 'assistant' || role.name === 'activeInvestor'
    );

    return (
        !isAdminAndCanEditOrderValue &&
        !isOrderEditor &&
        (isFundSponsorAdminValue || isHoldingOptionAdminValue || isReviewerValue)
    );
};

// Order view by role and status:
// - sysAdmin, reviewer: always sees order details unless overridden by another role
// - admin, advisor, assistant, activeInvestor: sees order process if order is in steps 1 - 5, detail otherwise
//   - exception: if order is in presignature review and user can review, user lands on order details
export const userSeesOrderProcess = (
    profile: Profile,
    status: string,
    orderRepCode: ProfileRepCode,
    orderFirmId: string
) => {
    const hasOrderEditorRole =
        profile.roles.filter(
            role =>
                role.name === 'advisor' ||
                role.name === 'assistant' ||
                role.name === 'activeInvestor'
        ).length > 0;

    if (
        hasOrderEditorRole &&
        userHasOrderRepcode(profile, orderRepCode) &&
        orderStatusIndex[status as keyof Steps] < orderStatusIndex[orderStatus.pendingFirmApproval]
    ) {
        return true;
    }

    if (
        (isAdminAndCanEditOrder(profile, orderFirmId) || hasReadOnlyAccess(profile, orderFirmId)) &&
        orderStatusIndex[status as keyof Steps] < orderStatusIndex[orderStatus.pendingFirmApproval]
    ) {
        if (
            profile.roles.some(role => role.name === 'reviewer') &&
            status === orderStatus.pendingPresignatureReview
        ) {
            return false;
        }
        return true;
    }

    return false;
};

export const trainingMessages = {
    trainingCompleted: () =>
        `<span class="u-success-green"><span class="fa-regular fa-circle-check u-mr8"></span>The soliciting advisor has completed training.</span>`,
    trainingCompletedMultiple: () =>
        `<span class="u-success-green"><span class="fa-regular fa-circle-check u-mr8"></span>All advisors have completed training.</span>`,
    trainingRequiredAdvisors: (requiredTrainingUsers: string[]) =>
        `<span class="u-error-red"><span class="fa-regular fa-circle-xmark u-mr8"></span>Training Required: ${requiredTrainingUsers.join(
            ', '
        )}. All advisors must complete this requirement to continue.</span>`,
    trainingRequiredPartialAdvisors: (
        completedTrainingUsers: string[],
        requiredTrainingUsers: string[]
    ) => `
    <p class="u-success-green u-mb8">
        <span class="fa-regular fa-circle-check u-mr8"></span>Training Complete: ${completedTrainingUsers.join(
            ', '
        )}.
    </p>
    <p class="u-error-red">
        <span class="fa-regular fa-circle-check u-mr8"></span>Training Required: ${requiredTrainingUsers.join(
            ', '
        )}. All advisors must complete this requirement in order to continue.
    </p>`,
    trainingRequiredSolicitingAdvisor: (requiredTrainingUsers: string[]) =>
        `<span class="u-error-red"><span class="fa-regular fa-circle-xmark u-mr8"></span>Training Required: ${requiredTrainingUsers.join(
            ', '
        )}. Only the soliciting advisor needs to complete training.</span>`
};

export const hasEntityPermission = (
    currentApproval: OrderCurrentApproval | undefined,
    profile: Profile
) => {
    if (currentApproval && currentApproval.actingEntityType && currentApproval.actingEntityId) {
        switch (currentApproval.actingEntityType) {
            case 'firm':
                return (
                    !!profile.firm && profile.firm.id === currentApproval.actingEntityId.toString()
                );
            case 'holdingOption':
                return (
                    !!profile.holdingOption &&
                    profile.holdingOption.id === currentApproval.actingEntityId.toString()
                );
            case 'fundSponsor':
                return (
                    !!profile.fundSponsor &&
                    profile.fundSponsor.id === currentApproval.actingEntityId
                );
        }
    }
    return false;
};

export const hasApproverRole = (currentApprovalRoles: string[], userRoles: Role[]): boolean => {
    const userRoleStrings = userRoles.map(role => role.name);
    return userRoleStrings.filter(role => currentApprovalRoles.includes(role)).length > 0;
};

export const hasEntityApprovalPermission = (
    currentApproval: OrderCurrentApproval | undefined,
    profile: Profile
): boolean =>
    objectHasValue(currentApproval) &&
    hasEntityPermission(currentApproval, profile) &&
    hasApproverRole(currentApproval.roles, profile.roles);

/**
 * Formats the given number amount to a currency string (en-US, $, USD, 0.2-2);
 * @param amount The number amount to format;
 */
export const formatAmountToCurrency = (amount: number) => {
    return formatCurrency(amount, 'en-US', '$', 'USD', '0.2-2');
};

/**
 * Pulls the number amount data out of the given error message;
 * @param message The error message to pull the amount data out of;
 */
const getMinimumAmountFromErrorMessage = (message: string) => {
    if (message) {
        const matcher = message.match(/(\d*\,?\.?)(\d*)/g);
        return matcher !== null ? parseFloat(matcher.join('').split(',').join('')) : null;
    }
    return null;
};

export const minimumInvestmentNotifications = {
    error: (error: ApiFormErrorItem, purchaseType: string) => {
        const message = [];
        const minimumAmount = formatAmountToCurrency(
            getMinimumAmountFromErrorMessage(error.message) as number
        );
        message.push('You may not proceed with purchases below the minimum purchase amount.');
        if (minimumAmount !== null) {
            message.push(`${purchaseType} purchase minimum is ${minimumAmount}.`);
        }
        return message.join('<br>');
    }
};

export const isDisabledFile = (file: File | DocumentFile) => {
    if (!file || !file.name || file.name.lastIndexOf('.') < 0) {
        return false;
    }
    const fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
    return fileType === 'csv' || fileType === 'xlsx';
};

export const getDocumentIndexById = (filesToDisplay: DocumentFile[], id: string | null) => {
    return filesToDisplay.findIndex(item => item.id === id);
};
