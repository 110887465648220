import {
    Component,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    OnInit,
    output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { isBoolean } from 'lodash-es';

@Component({
    selector: 'aix-slide-toggle',
    templateUrl: './aix-slide-toggle.component.html',
    styleUrls: ['./aix-slide-toggle.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixSlideToggleComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective, NgClass]
})
export class AixSlideToggleComponent implements OnInit, OnChanges, ControlValueAccessor {
    offText = input('');
    onText = input('');
    config = input<any>();
    required = input(false);
    isRequired = input(false);
    isDisabled = input(false);
    isValid = input(false);
    allowDecimal = input(true);
    bodyClassNames = input<string[]>();

    valueToRender = model(false);

    initialValue = input<boolean>();

    valueChanges = output<boolean>();

    @HostBinding('attr.aix-control')
    aixControl: string;

    _selection = <string[]>[];
    _propagateChanges: (value: boolean) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    isSelected: boolean;
    text = '';

    constructor() {}

    ngOnInit() {
        this.text = this.valueToRender() ? this.onText() : this.offText();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            if (isBoolean(this.initialValue())) {
                this.valueToRender.set(<boolean>this.initialValue());
            }
        }
    }

    writeValue(value: boolean) {
        this.valueToRender.set(value);
        this.text = this.valueToRender() ? this.onText() : this.offText();
        return this.valueToRender();
    }

    valueToSend(e: Event) {
        const val = (e.target as HTMLInputElement).checked;
        this.valueToRender.set(val);
        this.text = this.valueToRender() ? this.onText() : this.offText();
        this.valueChanges.emit(val);
        this._propagateChanges(val);
    }

    registerOnChange(fn: (value: boolean) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
