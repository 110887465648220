import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import {
    BaseFieldConfig,
    BasePropertyFieldConfig,
    BodyFormPropertyFieldConfig,
    BodyGroupPropertyFieldConfig,
    HeaderPropertyFieldConfig,
    HostFormPropertyFieldConfig,
    HostGroupPropertyFieldConfig,
    ParametrizedStoreAction,
    RepeaterFieldConfig,
    SelectFieldConfig
} from '@trade-platform/form-fields';
import { LogService } from '@trade-platform/ui-utils';
import { Logger } from 'typescript-logging';
import { getPropertyClass } from './dynamic-form.constants';
import { ObservableMapFuncts } from './dynamic-form.utils';

/**
 * Type checkers
 */

const isParametrizedStoreAction = (
    action: string | ParametrizedStoreAction
): action is ParametrizedStoreAction =>
    (action as ParametrizedStoreAction).actionName !== undefined;

export interface FieldMapType {
    path: string;
    maskFormat?: string;
    maskType?: string;
    required?: boolean;
    options?: string[];
    checkboxOptions?: any;
    limit?: number;
    useDisplayValueKey?: boolean;
    displayValueKey?: string | any[];
    labelField?: string;
    originalPath?: string;
    label?: string;
    alternativeRefId?: string;
}

@Injectable()
export class DynamicFormHelper {
    readonly LOG: Logger;
    actionFactory: { [key: string]: (...args: any[]) => Action };

    private _observableMapFuncts: ObservableMapFuncts;
    get observableMapFuncts() {
        return this._observableMapFuncts;
    }

    readonly INPUT_DEBOUNCE: number = 0;

    constructor(private logService: LogService) {
        this.LOG = this.logService.getLogger('components.dynamic-form.dynamic-form.helper');
    }

    setObservableMapFuncts(observableMapFuncts: ObservableMapFuncts) {
        this._observableMapFuncts = observableMapFuncts;
    }

    // --------------------------------
    //
    //  Css Class Helpers
    //
    // --------------------------------
    parseHostProperties(
        properties?: HostGroupPropertyFieldConfig | HostFormPropertyFieldConfig
    ): string[] {
        return this.parseProperties(properties, getPropertyClass());
    }

    parseHeaderProperties(properties: HeaderPropertyFieldConfig): string[] {
        return this.parseProperties(properties, getPropertyClass());
    }

    parseBodyProperties(
        properties: BodyGroupPropertyFieldConfig | BodyFormPropertyFieldConfig | undefined
    ): string[] {
        return [...this.parseProperties(properties, getPropertyClass())];
    }

    parseProperties(
        properties:
            | (HostGroupPropertyFieldConfig &
                  HostFormPropertyFieldConfig &
                  HeaderPropertyFieldConfig &
                  BodyGroupPropertyFieldConfig &
                  BodyFormPropertyFieldConfig)
            | undefined,
        classProps: { [key: string]: string }
    ): string[] {
        if (!properties) {
            return [];
        }

        const keys = Object.keys(classProps).filter(e => {
            return properties.hasOwnProperty(e);
        });

        return keys.map(e => {
            switch (e) {
                case 'marginTop':
                case 'marginBottom':
                    const marginBottom = `${classProps[e]}${properties[e]}`;
                    return marginBottom;
                case 'columns':
                case 'container':
                case 'size':
                case 'grid':
                    return !properties[e]
                        ? classProps[e]
                        : `${classProps[e]} ${classProps[e]}--${properties[e]}`;
                case 'customClass':
                    return typeof properties[e] === 'string' ? (properties[e] as string) : '';
                default:
                    return classProps[e] && properties[e as keyof BasePropertyFieldConfig]
                        ? classProps[e]
                        : '';
            }
        });
    }

    // --------------------------------
    //
    //  Other stuff
    //
    // --------------------------------

    dispatchOnLoadStoreActions(store: Store<any>, config: BaseFieldConfig, value: any) {
        if (config.storeActionOnLoad && config.storeActionOnLoad !== '') {
            const actions =
                typeof config.storeActionOnLoad === 'string'
                    ? [config.storeActionOnLoad]
                    : config.storeActionOnLoad;
            actions.forEach((action: string | ParametrizedStoreAction) => {
                if (typeof action === 'string') {
                    store.dispatch(this.actionFactory[action](value));
                } else if (isParametrizedStoreAction(action)) {
                    store.dispatch(this.actionFactory[action.actionName](value, action.params));
                } else {
                    throw new TypeError(
                        `Unknown onLoadStoreAction kind: ${JSON.stringify(action)}`
                    );
                }
            });
        }
    }

    dispatchStoreActions(store: Store<any>, config: BaseFieldConfig, value: any) {
        if (config.storeAction && config.storeAction !== '') {
            const actions =
                typeof config.storeAction === 'string' ? [config.storeAction] : config.storeAction;
            actions.forEach((action: string | ParametrizedStoreAction) => {
                if (typeof action === 'string') {
                    store.dispatch(this.actionFactory[action](value));
                } else if (isParametrizedStoreAction(action)) {
                    store.dispatch(this.actionFactory[action.actionName](value, action.params));
                } else {
                    throw new TypeError(`Unknown storeAction kind: ${JSON.stringify(action)}`);
                }
            });
        }
    }

    dispatchOnRemoveStoreActions(store: Store<any>, config: RepeaterFieldConfig, value: any) {
        if (config.storeActionOnRemove && config.storeActionOnRemove !== '') {
            const actions =
                typeof config.storeActionOnRemove === 'string'
                    ? [config.storeActionOnRemove]
                    : config.storeActionOnRemove;
            actions.forEach(elem => store.dispatch(this.actionFactory[elem](value)));
        }
    }

    dispatchOnFilterStoreActions(store: Store<any>, config: SelectFieldConfig, value: string) {
        if (config.storeActionOnFilter && config.storeActionOnFilter !== '') {
            const actions =
                typeof config.storeActionOnFilter === 'string'
                    ? [config.storeActionOnFilter]
                    : config.storeActionOnFilter;
            actions.forEach((action: string | ParametrizedStoreAction) => {
                if (typeof action === 'string') {
                    store.dispatch(this.actionFactory[action](value));
                } else if (isParametrizedStoreAction(action)) {
                    store.dispatch(this.actionFactory[action.actionName](value, action.params));
                } else {
                    throw new TypeError(
                        `Unknown onFilterStoreAction kind: ${JSON.stringify(action)}`
                    );
                }
            });
        }
    }
}
