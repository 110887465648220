import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    input,
    OnChanges,
    OnDestroy,
    output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { BaseOrder } from '@trade-platform/ui-shared';
import { interval, Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { pdfSubtitles, pdfTitles } from './pdf-generation.constants';
import { DetailViewState } from '../overview';
import { areFormUploadsCompletedInCurrentStep } from '../utils/order-utils';
import { idCheckType } from '@trade-platform/lib-enums';
import { Router } from '@angular/router';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { AixDataTestingDirective, AixLoadingComponent } from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, getFilesToDisplay, ORDERS_STORE_FACADE } from '@advisor-ui/orders';

@Component({
    selector: 'aix-pdf-generation',
    templateUrl: './pdf-generation.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AixLoadingComponent,
        AixDataTestingDirective,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault
    ]
})
export class AixPdfGenerationComponent implements OnChanges, OnDestroy {
    order = input<BaseOrder>();
    viewState = input<DetailViewState>();
    signaturesCompleted = input<boolean>(false);
    wetSignOnly = input<boolean>(false);

    onMakeChanges = output();
    onGenerateFiles = output();

    readonly reducerSuffix = this.storeFacade.type;
    subscriptions: Subscription[] = [];
    pollFilesSubscription: Subscription | null;

    filesGenerated: boolean;
    unfinishedUploads = true;
    showError = false;
    retries = 12;

    smsAuthentication = false;

    pdfTitles = pdfTitles;
    pdfSubtitles = pdfSubtitles;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.order && changes.order.currentValue && this.order()) {
            this.checkOrder(this.order() as BaseOrder);
        }
    }

    getPdfTitle(s: string) {
        return pdfTitles[s as keyof typeof pdfTitles];
    }

    checkOrder(ord: BaseOrder) {
        if (ord) {
            this.unfinishedUploads = !areFormUploadsCompletedInCurrentStep(ord);
            this.filesGenerated = this.filesAreGenerated(ord);
            this.smsAuthentication = this.checkOrderAuthentication(ord);
            if (!this.filesGenerated && !this.pollFilesSubscription) {
                this.pollFilesSubscription = interval(5000)
                    .pipe(
                        mergeMap(() => this.storeFacade.getOrder(ord.id) as Observable<BaseOrder>)
                    )
                    .subscribe(order => {
                        this.filesGenerated = this.filesAreGenerated(order);

                        if (this.filesGenerated) {
                            this.onGenerateFiles.emit();

                            this.storeFacade.actions.getOrder.dispatch({
                                orderId: order.id,
                                reducerSuffix: this.reducerSuffix
                            });

                            this.stopPolling();
                        } else {
                            this.retries--;

                            if (this.retries === 0) {
                                this.stopPolling();
                                this.showError = true;
                                this.changeDetectorRef.detectChanges();
                            }
                        }
                    });
            } else {
                this.onGenerateFiles.emit();
            }
        } else if (this.pollFilesSubscription) {
            this.stopPolling();
        }
    }

    stopPolling() {
        if (this.pollFilesSubscription) {
            this.pollFilesSubscription.unsubscribe();
            this.pollFilesSubscription = null;
        }
    }

    filesAreGenerated(ord: BaseOrder) {
        return (
            ord &&
            ord.files &&
            ord.forms &&
            Object.keys(ord.forms)
                .map(formId => ord.forms[formId])
                .filter(form => form.isOnboarded !== false)
                .every(form => ord.files.some(file => file.formId === form.id))
        );
    }

    checkOrderAuthentication(ord: BaseOrder): boolean {
        return (
            ord &&
            !!ord.eSign &&
            !!ord.eSign.envelope &&
            ord.eSign.envelope.idCheckConfig === idCheckType.sms
        );
    }

    viewOrder(docId: string | null = null) {
        const order = this.storeFacade.order;
        const filesToDisplay = getFilesToDisplay(order);
        const id = docId ? docId : filesToDisplay[0].id;
        if (this.order()?.id) {
            this.router.navigate(
                this.storeFacade.routes.documentViewer((this.order() as BaseOrder).id, id)
            );
        }
    }

    ngOnDestroy() {
        this.stopPolling();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
