<aix-header-section [title]="'Send for eSign'" [data-testing]="'signing-method-header'">
    <aix-button
        (click)="cancelESign.emit()"
        [data-testing]="'cancel-e-sign'"
        [buttonType]="cancelEsignButtonType"
        [buttonLabel]="'Cancel eSign'"
    >
    </aix-button>
</aix-header-section>

<div>
    @if ((signers() | aixSignerFilter:recipientType.investor)?.length) {
    <h5 class="u-mt16" [data-testing]="'investor-signers-title'">Investors</h5>
    } @for (signer of (signers() | aixSignerFilter:recipientType.investor); track signer) {
    <aix-signer [recipient]="signer"></aix-signer>
    } @if ((signers() | aixSignerFilter:recipientType.additional)?.length) {
    <h5 class="u-mt16" [data-testing]="'additional-signers-title'">Additional Signers</h5>
    } @for (signer of (signers() | aixSignerFilter:recipientType.additional); track signer) {
    <aix-signer [recipient]="signer"></aix-signer>
    } @if ((signers() | aixSignerFilter:recipientType.advisor)?.length) {
    <h5 class="u-mt16" [data-testing]="'investor-signers-title'">Advisors</h5>
    } @for (signer of (signers() | aixSignerFilter:recipientType.advisor); track signer) {
    <aix-signer [recipient]="signer"></aix-signer>
    } @if ((signers() | aixSignerFilter:recipientType.postAdvisor)?.length) {
    <h5 class="u-mt16" [data-testing]="'post-advisor-signers-title'">Additional Signers</h5>
    } @for (signer of (signers() | aixSignerFilter:recipientType.postAdvisor); track signer) {
    <aix-signer [recipient]="signer"></aix-signer>
    }
</div>

<form [formGroup]="sendForm">
    <h5 class="u-mt16">Message</h5>
    <div class="aix-form aix-form--centered">
        <div class="u-mt16">
            <div>
                <div class="aix-form__group">
                    <aix-input
                        formControlName="emailFrom"
                        [isValid]="true"
                        [isRequired]="true"
                        [isDisabled]="true"
                    >
                        From
                    </aix-input>
                </div>
                <div class="aix-form__group">
                    <aix-input
                        formControlName="emailSubject"
                        [config]="{ refId: 'email-input' }"
                        [isValid]="sendForm.get('emailSubject')?.valid"
                        [isRequired]="true"
                    >
                        Subject
                    </aix-input>

                    @if (sendForm.value?.emailSubject && sendForm.get('emailSubject')?.invalid) {
                    <div class="form-error--container">
                        <div class="form-error">
                            Email subject line must be less than 100 characters.
                        </div>
                    </div>
                    }
                </div>
                <div class="aix-form__group u-mt-1">
                    <aix-textarea
                        [config]="{ refId: 'body-input' }"
                        [height]="192"
                        [initialValue]="emailBody"
                        [isRequired]="true"
                        [isDisabled]="false"
                        [isValid]="sendForm.get('emailBody')?.valid"
                        formControlName="emailBody"
                        [data-testing]="'emailBody'"
                        >Required
                    </aix-textarea>
                </div>
            </div>

            <div class="u-mt24 aix-flex-grid__col aix-form__container">
                <aix-error-box [canClose]="true" [states]="states()"></aix-error-box>
            </div>

            <div class="aix-toolbar u-mt24">
                <aix-button
                    [data-testing]="'send-e-sign-button'"
                    (click)="onSubmit($event)"
                    [buttonType]="sendEsignButtonType"
                    [buttonLabel]="'Send for eSign'"
                    [isDisabled]="sendForm?.invalid"
                >
                </aix-button>
            </div>
        </div>
    </div>
</form>
