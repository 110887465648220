@if ((storeFacade.orderApprovalFormsRemoteData$ | async | isInProgress) ||
(storeFacade.orderRemoteData$ | async | isInProgress)) {
<aix-loading></aix-loading>
} @if (!hideFilelist()) {
<aix-form-filelist
    [isReadOnly]="isReadOnly()"
    [acceptedFormIds]="acceptedFormIds()"
    (onDownload)="onDownloadFile($event)"
    (onUpload)="onUploadDocuments()"
    (onEdit)="setRelations()"
    (onReplace)="onReplaceUpload($event)"
    (onRemove)="removeFile($event)"
>
</aix-form-filelist>
}

<aix-modal
    #uploadModal
    modalType="interactive"
    [modalZIndex]="6000"
    [modalTitle]="'Upload Documents'"
>
    <ng-template #uploadTemplate>
        <div class="order-detail-modal">
            <div class="u-mt16">
                <aix-upload-document
                    #uploadDocumentsRef
                    [uploadUrl]="uploadUrl"
                    [type]="fileType.form"
                    [isModal]="true"
                    [fileSizeLimit]="fileSizeLimit"
                    (filesSelected)="onFilesSelected($event)"
                    (filesUploaded)="onFilesUploaded()"
                    (uploadError)="onFileUploadError()"
                >
                </aix-upload-document>
                @if (!uploadStarted && selectedFiles.length) {
                <div class="files-selected">
                    @for (file of selectedFiles; track file) {
                    <div class="file-row">
                        <div class="file-row__name">
                            <span class="file-row__name--text">{{ file.name }}</span>
                        </div>

                        <span class="file-row__actions">
                            <span
                                class="file-row__actions--close fa-regular fa-xmark"
                                (click)="removeFileSelected(file)"
                            ></span>
                        </span>
                    </div>
                    }
                </div>
                } @if (hasExtraFormUploads() && !uploadStarted) {
                <aix-notification
                    [isOpen]="true"
                    [canClose]="false"
                    [classList]="['u-mt16']"
                    status="warning"
                >
                    You have uploaded {{ getExtraUploadsCount() }} too many files, please remove any
                    extra files that are not part of the requested documents.
                </aix-notification>
                }
            </div>

            <div class="aix-toolbar u-mt24">
                @if (!fileUploaded) {
                <aix-button
                    (click)="clickCancelUpload()"
                    [buttonLabel]="'Cancel'"
                    [buttonType]="cancelButtonType"
                >
                </aix-button>
                } @if (!uploadStarted && !uploadDocumentsRef.hasFileError()) {
                <aix-button
                    (click)="clickStartUpload()"
                    [isDisabled]="isStartUploadDisabled()"
                    [buttonLabel]="'Continue'"
                    [buttonType]="continueButtonType"
                >
                </aix-button>
                } @if (!uploadStarted && uploadDocumentsRef.hasFileError()) {
                <aix-button
                    (click)="returnToUploader()"
                    [buttonLabel]="'Close Uploader'"
                    [buttonType]="closeUploaderButtonType"
                >
                </aix-button>
                } @if (uploadStarted) {
                <aix-button
                    (click)="clickContinueUpload()"
                    [isDisabled]="!fileUploaded || fileUploadError"
                    [buttonLabel]="'Continue'"
                    [buttonType]="continueButtonType"
                >
                </aix-button>
                }
            </div>
        </div>
    </ng-template>
</aix-modal>

<aix-modal
    #replaceModal
    modalType="interactive"
    [modalZIndex]="6000"
    [modalTitle]="getReplaceModalTitle()"
>
    <ng-template #replaceTemplate>
        <div class="order-detail-modal">
            @for (comment of unresolvedFileComments; track comment) {
            <p class="order-detail-modal-comments u-mb16">{{ comment.text }}</p>
            } @if (unresolvedFileComments.length > 0) {
            <p>{{ getOrderFileCommentsText() }}</p>
            }

            <div class="u-mt16">
                <aix-upload-document
                    #replaceDocumentsRef
                    [uploadUrl]="uploadUrl"
                    [type]="fileType.form"
                    [disabled]="selectedFiles.length > 0"
                    [multiple]="false"
                    [isModal]="true"
                    [fileSizeLimit]="fileSizeLimit"
                    (filesSelected)="onFilesSelected($event)"
                    (filesUploaded)="onFilesUploaded()"
                    (uploadError)="onFileUploadError()"
                >
                </aix-upload-document>
                @if (!uploadStarted && selectedFiles.length) {
                <div class="files-selected">
                    @for (file of selectedFiles; track file) {
                    <div class="file-row">
                        <div class="file-row__name">
                            <span class="file-row__name--text">{{ file.name }}</span>
                        </div>

                        <span class="file-row__actions">
                            <span
                                class="file-row__actions--close fa-regular fa-xmark"
                                (click)="removeFileSelected(file)"
                            ></span>
                        </span>
                    </div>
                    }
                </div>
                }
            </div>

            <div class="aix-toolbar u-mt24">
                @if (!fileUploaded) {
                <aix-button
                    (click)="clickCancelReplace()"
                    [buttonType]="cancelButtonType"
                    [buttonLabel]="'Cancel'"
                >
                </aix-button>
                } @if (!uploadStarted && !replaceDocumentsRef.hasFileError()) {
                <aix-button
                    (click)="clickStartReplace()"
                    [isDisabled]="selectedFiles.length === 0"
                    [buttonType]="continueButtonType"
                    [buttonLabel]="'Continue'"
                >
                </aix-button>
                } @if (!uploadStarted && replaceDocumentsRef.hasFileError()) {
                <aix-button
                    (click)="returnToUploader()"
                    [buttonType]="closeUploaderButtonType"
                    [buttonLabel]="'Close Uploader'"
                >
                </aix-button>
                } @if (uploadStarted) {
                <aix-button
                    (click)="clickContinueReplace()"
                    [isDisabled]="!fileUploaded || fileUploadError"
                    [buttonType]="continueButtonType"
                    [buttonLabel]="'Continue'"
                >
                </aix-button>
                }
            </div>
        </div>
    </ng-template>
</aix-modal>

<aix-modal
    #deleteModal
    [data-testing]="'delete-modal'"
    modalType="confirmation"
    [modalZIndex]="6000"
    [modalTitle]="'Remove document?'"
    (optionSelected)="onRemoveUploadOptionSelected($event)"
>
    <ng-template #deleteTemplate>
        <p [data-testing]="'modal-message-1'" class="u-fw500">This document will be removed.</p>
        <p [data-testing]="'modal-message-2'" class="u-mb16">
            You can upload a new version of this document.
        </p>
    </ng-template>
</aix-modal>

<aix-modal
    #ignoreModal
    [modalZIndex]="6000"
    modalType="confirmation"
    [modalTitle]="'Ignore document?'"
    (optionSelected)="onIgnoreUploadOptionSelected($event)"
    (onCloseModal)="onCloseIgnoreUploadModal()"
>
    <ng-template #ignoreTemplate>
        <p class="u-fw500">This document will be ignored.</p>
        <p class="u-mb16">
            You may be required to provide additional clarification for this order.
        </p>
    </ng-template>
</aix-modal>
