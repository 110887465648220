import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    viewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    AixStepProgressComponent,
    BaseOrder,
    OrderFormComment,
    RequiredDocument
} from '@trade-platform/ui-shared';
import {
    getFinishedSupplementalUploads,
    getRequiredDocumentEntityName,
    getRequiredFilesToUpload,
    getTotalSupplementalUploads,
    getUnfinishedRequiredUploads,
    getUploadedSupplementalFiles
} from '../../utils/order-utils';
import { AixFileUploadComponent } from '../../../../file-upload/file-upload';
import { AixOrderStepComponent } from '../step/order-step';
import { commentStatus, commentTag } from '@trade-platform/lib-enums';
import {
    AixFilelistComponent,
    AixRequiredFilesMenuOption,
    AixUploadedFilesMenuOption
} from '../../../../file-upload/filelist/filelist';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixHeaderSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { OrderOverview } from '../../../../../../../language/language.interface';
import { getLanguage } from '../../../../../../../language/language.base';
import { AixTemplatesComponent } from '@advisor-ui/app-components';
import { KeyValuePipe, NgFor, NgIf } from '@angular/common';

export interface RequiredFileInfo {
    documents: string[];
    entityName: string;
}
export interface CombinedRequiredFileInfo {
    [fileType: string]: RequiredFileInfo[];
}

@Component({
    selector: 'aix-uploads-step',
    templateUrl: './uploads-step.html',
    styleUrls: ['./uploads-step.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixTemplatesComponent,
        NgIf,
        AixStepProgressComponent,
        AixDataTestingDirective,
        NgFor,
        AixHeaderSectionComponent,
        AixButtonComponent,
        AixFileUploadComponent,
        AixExpansionPanelComponent,
        KeyValuePipe
    ]
})
export class AixUploadsStepComponent extends AixOrderStepComponent implements OnInit {
    orderFileUploadRef = viewChild<AixFileUploadComponent>('orderFileUploadRef');

    aixTemplatesInitialized = false;

    totalFileComments = 0;
    totalUnresolvedFileComments = 0;

    orderComments: OrderFormComment[];

    hasRequiredFiles = true;
    hasUploadedFiles = true;
    unfinishedRequiredUploads = true;
    unfinishedUploads = true;
    unfinishedUploadsProgress = 0;
    unfinishedUploadsTotal = 0;

    requiredFiles: RequiredDocument[] = [];
    requiredFileInfo: CombinedRequiredFileInfo;
    arrayFiles: AixUploadedFilesMenuOption[] = [];
    arrayRequiredFiles: AixRequiredFilesMenuOption[] = [];

    uploadButtonType = BUTTON_TYPE.link;
    uploadDocumentsButtonType = BUTTON_TYPE.secondary;
    continueButtonType = BUTTON_TYPE.primary;
    uploadLaterButtonType = BUTTON_TYPE.primary;

    language: OrderOverview = getLanguage('orderOverview');
    hideUploadDescriptionOwner = !!this.language?.hideUploadDescriptionOwner;

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.actions.finalizeOrder.success$.subscribe(action => {
                // this.loadOrder();
                this.router.navigate(this.storeFacade.routes.process.reviewOrder(this.orderId));
            }),
            this.storeFacade.actions.removeDocument.success$.subscribe(() => {
                const order: BaseOrder = this.storeFacade.order;
                this.checkOrder(order);
            }),
            this.storeFacade.actions.getFormComments.success$.subscribe(action => {
                this.updateComments(action.payload);
            })
        );

        this.order = this.storeFacade.order;
        if (this.order) {
            this.onOrderUpdated(this.order);
        }
    }

    onOrderUpdated(order: BaseOrder): void {
        if (order) {
            if (order.files) {
                this.arrayFiles = getUploadedSupplementalFiles(order);
            }
            if (order.requiredSupplementalFiles) {
                this.arrayRequiredFiles = getRequiredFilesToUpload(order);
            }
            this.checkOrder(order);
        }
    }

    checkOrder(order: BaseOrder) {
        if (order) {
            this.hasRequiredFiles = order.requiredSupplementalFiles.length > 0;

            // Add requiring entity names and group by type
            this.requiredFiles = order.requiredSupplementalFiles.map(required => ({
                ...required,
                entityName: getRequiredDocumentEntityName(order, required as RequiredDocument)
            }));

            if (this.requiredFiles) {
                this.requiredFileInfo = {};
                this.requiredFiles.forEach(required => {
                    if (
                        required.entityName !== undefined &&
                        required.documents &&
                        required.documents.length > 0 &&
                        required.type
                    ) {
                        const newEntry: RequiredFileInfo = {
                            documents: required.documents,
                            entityName: required.entityName || ''
                        };
                        if (this.requiredFileInfo[required.type]) {
                            if (
                                !this.requiredFileInfo[required.type].find(
                                    entry => entry.entityName === required.entityName
                                )
                            ) {
                                this.requiredFileInfo[required.type].push(newEntry);
                            }
                        } else {
                            this.requiredFileInfo[required.type] = [newEntry];
                        }
                    }
                });

                this.unfinishedRequiredUploads = getUnfinishedRequiredUploads(order) > 0;
                this.unfinishedUploads = this.unfinishedRequiredUploads;
                this.unfinishedUploadsProgress = getFinishedSupplementalUploads(order);
                this.unfinishedUploadsTotal = getTotalSupplementalUploads(order);

                this.hasUploadedFiles = order.files && order.files.length > 0;

                if (isNaN(this.unfinishedUploadsProgress)) {
                    this.hasUploadedFiles = false;
                    this.unfinishedUploadsProgress = 0;
                    this.unfinishedUploads = false;
                }

                if (!this.unfinishedUploads) {
                    this.hasUploadedFiles = true;
                }
            }

            this.loadOrderFormComments();

            this.ref.detectChanges();
        }
    }

    updateComments(comments: OrderFormComment[]) {
        this.orderComments = this.orderFormCommentsPipe.transform(
            comments,
            false,
            false,
            undefined,
            commentTag.change
        );
        this.totalFileComments = this.orderComments.length;
        this.totalUnresolvedFileComments = this.orderComments.filter(
            c => c.status === commentStatus.unresolved
        ).length;
        this.ref.detectChanges();
    }

    uploadDocuments() {
        this.orderFileUploadRef()?.uploadDocuments();
    }

    finish() {
        this.storeFacade.actions.finalizeOrder.dispatch({ orderId: this.orderId });
    }

    disableContinueButton() {
        if (this.orderFileUploadRef() && this.orderFileUploadRef()?.filelistRef()) {
            const fileOpen =
                (<AixFilelistComponent>(
                    (<AixFileUploadComponent>this.orderFileUploadRef()).filelistRef()
                )).arrayFiles.filter(file => !!file.isOpen).length > 0;

            const unlabeledFileOpen =
                (<AixFilelistComponent>(
                    (<AixFileUploadComponent>this.orderFileUploadRef()).filelistRef()
                )).arrayUnlabeledFiles.filter(file => !!file.isOpen).length > 0;
            const requiredFileOpen =
                (<AixFilelistComponent>(
                    (<AixFileUploadComponent>this.orderFileUploadRef()).filelistRef()
                )).arrayRequiredFiles.filter(file => !!file.isOpen).length > 0;

            return (
                fileOpen ||
                unlabeledFileOpen ||
                requiredFileOpen ||
                this.totalUnresolvedFileComments > 0
            );
        }
        return false;
    }

    getUploadLabel() {
        if (this.hasRequiredFiles && this.unfinishedRequiredUploads) {
            return 'There are requested supporting documents for this order.\nPlease upload or upload later.';
        } else if (this.hasRequiredFiles && !this.unfinishedUploads) {
            return 'All of your required documents have been uploaded and labeled. Please continue to collect signatures.';
        } else if (!this.hasRequiredFiles && !this.unfinishedUploads) {
            return 'There are no requested supporting documents for this order.\nPlease continue or upload other documents.';
        } else if (this.unfinishedUploads && !this.unfinishedRequiredUploads) {
            return 'All of your required documents have been uploaded and labeled. Please label your supporting documents in order to continue to collect signatures.';
        }
        return undefined;
    }
}
