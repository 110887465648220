import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorWrapper } from '../models/common';
import { catchHttpError } from '../utils/catch-http-error';
import {
    LoadFirmsAction,
    LoadFirmsActionTypes,
    LoadFirmsFailAction,
    LoadFirmsSuccessAction,
    LoadProfileAction,
    LoadProfileFromStorageAction,
    ProfileActionTypes,
    ProfilePasswordReset,
    ProfilePasswordResetFailure,
    ProfilePasswordResetSuccess,
    ProfilePasswordResetTypes,
    ResetMFA,
    ResetMFAFailure,
    ResetMFASuccess,
    ResetMFATypes,
    SetProfileAction,
    UnloadProfileAction,
    UpdateProfileAction,
    UpdateProfileFailAction,
    UpdateProfileSuccessAction
} from './actions';
import { Profile } from '../../models/profile/model';
import { AixUsersService } from './service';
import { setLanguage } from '../../../../../advisor-ui/language/language.base';

@Injectable()
export class ProfileEffects {
    constructor(private actions$: Actions, private usersService: AixUsersService) {}

    private updateProfileRepCodes = (profile: Profile) => {
        profile.repCodes?.forEach(item => (item.listName = `${item.fullName} - ${item.repCode}`));
        return profile;
    };

    updateAdvisorLogged$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateProfileAction>(ProfileActionTypes.UPDATE_PROFILE),
            switchMap(action =>
                this.usersService.updateAdvisorLogged(action.payload).pipe(
                    map((profile: Profile) => {
                        profile = this.updateProfileRepCodes(profile);
                        setToStorage('profile', profile);

                        return new UpdateProfileSuccessAction(profile);
                    }),
                    catchHttpError(error => {
                        return of(new UpdateProfileFailAction({ error }));
                    })
                )
            )
        )
    );

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProfileAction>(ProfileActionTypes.LOAD_PROFILE),
            switchMap(action =>
                this.usersService.getAdvisorLogged().pipe(
                    map((profile: Profile) => {
                        profile = this.updateProfileRepCodes(profile);
                        setToStorage('profile', profile);
                        setLanguage();
                        return new UpdateProfileSuccessAction(profile);
                    }),
                    catchHttpError(error => {
                        return of(new UpdateProfileFailAction({ error }));
                    })
                )
            )
        )
    );

    loadProfileFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProfileFromStorageAction>(ProfileActionTypes.LOAD_PROFILE_FROM_STORAGE),
            map(() => {
                const profile = getFromStorage('profile') as Profile;
                setLanguage();
                return new SetProfileAction(profile);
            })
        )
    );

    setProfile$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetProfileAction>(ProfileActionTypes.SET_PROFILE),
                tap((action: SetProfileAction) => {
                    setToStorage('profile', action.payload);
                    setLanguage();
                })
            ),
        { dispatch: false }
    );

    unloadProfile$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<UnloadProfileAction>(ProfileActionTypes.UNLOAD_PROFILE),
                tap(() => {
                    withStorage(storage => storage.removeItem('profile'));
                })
            ),
        { dispatch: false }
    );

    resetPasswordReset$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ProfilePasswordReset>(ProfilePasswordResetTypes.PASSWORD_RESET),
            switchMap(() => {
                return this.usersService.resetPassword().pipe(
                    map(ok => {
                        return new ProfilePasswordResetSuccess(ok);
                    }),
                    catchHttpError(error => of(new ProfilePasswordResetFailure({ error })))
                );
            })
        )
    );

    resetMFAReset$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ResetMFA>(ResetMFATypes.RESET_MFA),
            switchMap(() => {
                return this.usersService.resetMFA().pipe(
                    map(ok => {
                        return new ResetMFASuccess(ok);
                    }),
                    catchError<Action, Observable<Action>>((error: string | HttpErrorResponse) => {
                        let err: ErrorWrapper;
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 404) {
                                return of(new ResetMFASuccess(true));
                            } else {
                                err = new ErrorWrapper(error.message);
                            }
                        } else {
                            err = new ErrorWrapper(error);
                        }
                        return of(new ResetMFAFailure({ error: err }));
                    })
                );
            })
        )
    );

    loadSharedFirms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadFirmsAction>(LoadFirmsActionTypes.LOAD_FIRMS),
            switchMap(action =>
                this.usersService.getSharedFirms().pipe(
                    map(firms => new LoadFirmsSuccessAction(firms)),
                    catchHttpError(error => of(new LoadFirmsFailAction({ error })))
                )
            )
        )
    );
}
