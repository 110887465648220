import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, viewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    AcceptTermsAction,
    AcceptTermsSuccessAction,
    AppState,
    ErrorWrapper,
    AixEffectActions,
    getDataFromState,
    LoadProfileAction,
    LoadTermsAction,
    LoadTermsFailureAction,
    LoadTermsSuccessAction,
    TermsAndConditions
} from '@trade-platform/ui-shared';
import { extractStreamValue } from '@trade-platform/ui-utils';
import { Logout, Redirect, LoginMachine, AixAuthService } from '@advisor-ui/app-services';
import { RemoteData } from 'ngx-remotedata';
import { Observable, Subscription } from 'rxjs';
import {
    AixButtonComponent,
    AixLoadingComponent,
    AixPageSectionComponent,
    AixPrintButtonComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-euaa',
    templateUrl: './euaa.html',
    styleUrls: ['./euaa.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AixLoadingComponent,
        AixPageSectionComponent,
        AixPrintButtonComponent,
        FormsModule,
        AixButtonComponent,
        AsyncPipe
    ]
})
export class AixEuaaComponent implements OnInit, OnDestroy {
    form = viewChild<NgForm>('formRef');

    terms$: Observable<TermsAndConditions>;
    termsState$: Observable<RemoteData<TermsAndConditions, ErrorWrapper>>;

    subscriptions: Subscription[] = [];

    acceptedTerms = false;

    acceptButtonType = BUTTON_TYPE.primary;

    constructor(
        public store: Store<AppState>,
        public authService: AixAuthService,
        public router: Router,
        public actions$: AixEffectActions,
        private loginMachine: LoginMachine
    ) {
        this.termsState$ = this.store.pipe(select(state => state.terms));
        this.terms$ = getDataFromState(this.termsState$);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.actions$
                .ofClass<LoadTermsSuccessAction>(LoadTermsSuccessAction)
                .subscribe(action => {
                    this.checkForAcceptance(action.payload);
                }),
            this.actions$
                .ofClass<AcceptTermsSuccessAction>(AcceptTermsSuccessAction)
                .subscribe(action => {
                    this.store.dispatch(new LoadProfileAction());
                    this.store.dispatch(new LoadTermsAction());
                    this.loginMachine.send(new Redirect());
                }),
            this.actions$
                .ofClass<LoadTermsFailureAction>(LoadTermsFailureAction)
                .subscribe(action => {
                    this.loginMachine.send(new Logout(null));
                })
        );

        const terms = extractStreamValue(this.terms$);
        if (!terms) {
            this.store.dispatch(new LoadProfileAction());
            this.store.dispatch(new LoadTermsAction());
        } else {
            this.checkForAcceptance(terms); // handle page reload;
        }
    }

    checkForAcceptance(terms: TermsAndConditions) {
        if (terms && terms.accepted) {
            this.loginMachine.send(new Redirect());
        }
    }

    acceptTerms() {
        this.store.dispatch(new AcceptTermsAction());
    }

    navigateToLogin() {
        this.authService.callLogout(true);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
