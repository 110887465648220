import {
    Component,
    ElementRef,
    input,
    OnChanges,
    OnInit,
    output,
    viewChild
} from '@angular/core';
import { AixButtonBarComponent, ButtonBarOption } from '@trade-platform/ui-components';
import { RouterLinkActive } from '@angular/router';
import { NgIf, TitleCasePipe } from '@angular/common';

export interface HeaderTab {
    label: string;
    tabName: 'buy' | 'maintenance' | 'productMaterials' | 'applications' | 'explore';
    title?: string;
}

@Component({
    selector: 'aix-header-tabs',
    templateUrl: './header-tabs.html',
    styleUrls: ['./header-tabs.scss'],
    standalone: true,
    imports: [NgIf, AixButtonBarComponent, RouterLinkActive, TitleCasePipe]
})
export class AixHeaderTabsComponent implements OnInit, OnChanges {
    tabsControlsRef = viewChild.required<ElementRef>('tabsControlsRef');

    selectedTab = input<'buy' | 'maintenance' | 'productMaterials' | 'applications' | 'explore'>();
    tabs = input<HeaderTab[]>();
    showTabs = input<boolean>(true);
    classNames = input<string>();
    titleCaseLabel = input<boolean>(true);

    onTabChange = output<HeaderTab>();

    showTabsControls: boolean;

    private static defaultTab: HeaderTab = { label: '', tabName: '' as any };
    selectedOption: HeaderTab = AixHeaderTabsComponent.defaultTab;

    ngOnInit() {
        this.showTabsControls =
            (this.tabsControlsRef().nativeElement.innerHTML ?? '').trim() !== '';
    }

    onChange(tab: ButtonBarOption) {
        this.onTabChange.emit(tab as HeaderTab);
    }

    ngOnChanges(): void {
        this.selectedOption =
            this.tabs()?.find(opt => opt.tabName === this.selectedTab()) ||
            AixHeaderTabsComponent.defaultTab;
    }
}
