<div
    class="aix-viewer__document-wrapper"
    [data-testing]="'document-viewer'"
    [ngStyle]="styles.pdfWrapper"
>
    <div class="aix-viewer__document">
        @if ((loadingFile$ | async) || (pdf()?.isLoading$ | async)) {
        <aix-loading [attr.style]="'border: 1px solid red'"></aix-loading>
        } @if (PDFJSloaded && pdfLoaded && pdfLoadError) {
        <div class="error-box">
            <div class="error-box__message">We apologize for the delay.</div>
            <div class="error-box__dev-message">
                A connectivity error has occurred. Please try again in a few minutes.
            </div>
        </div>
        } @if (isPdf) {
        <aix-pdf-viewer
            [margin]="{ top: 56, right: 0, bottom: 0, left: 0 }"
            [PDFJSready]="PDFJSloaded"
            [src]="pdfSource"
            [authToken]="authToken"
            (loadDocument)="onLoadDocument()"
            (loadDocumentError)="onLoadDocumentError($event)"
            (updatePage)="currentPage = $event + 1"
        >
        </aix-pdf-viewer>
        } @if (imageDataUrl) { @if (!isPdf && isSupportedFile) {
        <aix-image-viewer [src]="imageDataUrl" (loadImage)="onLoadImage()"></aix-image-viewer>
        } } @if (!isSupportedFile) {

        <aix-notification [status]="'warning'" [canClose]="false" [isOpen]="true">
            This document type can't be opened here. Please download to view.
        </aix-notification>

        }

        <div class="aix-viewer__controls-container">
            <div class="aix-viewer__controls aix-viewer__controls-top">
                <div class="aix-viewer__controls-inner__full">
                    <div class="aix-viewer__icon-wrapper">
                        <a
                            (click)="onClickPrevDocument()"
                            class="aix-viewer__left-arrow"
                            title="Previous Document"
                        >
                            <i
                                class="fa-regular fa-arrow-left aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <span class="aix-viewer__controls-label">
                        Document
                        <span class="aix-viewer__pager-white">{{ currentDocument() }} / </span>
                        {{ documentCount() }}
                    </span>
                    <div class="aix-viewer__icon-wrapper__right">
                        <a
                            (click)="onClickNextDocument()"
                            class="aix-viewer__right-arrow"
                            title="Next Document"
                        >
                            <i
                                class="fa-regular fa-arrow-right aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="aix-viewer__controls">
                <div class="aix-viewer__controls-inner__left">
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__zoom-out u-mt8" title="Zoom Out" (click)="zoomOut()">
                            <i
                                class="fa-regular fa-circle-minus aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a
                            class="aix-viewer__fullscreen-toggle"
                            title="Toggle Fullscreen"
                            [ngClass]="{ fullscreen: pdfFullscreen }"
                            (click)="onClickFullscreen()"
                        >
                            <i
                                class="fa-regular fa-maximize aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__rotate" title="Rotate" (click)="onClickRotate()">
                            <i
                                class="fa-regular fa-rotate-right aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__zoom-in" title="Zoom In" (click)="zoomIn()">
                            <i
                                class="fa-regular fa-circle-plus aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
                @if (canDisplayPagination()) {
                <div class="aix-viewer__controls-inner__right">
                    <div class="aix-viewer__controls-label">
                        Page <span class="aix-viewer__pager-white">{{ currentPage }} / </span>
                        {{ pdf()?.totalPages }}
                    </div>
                </div>
                } @if (!canDisplayPagination()) {
                <div class="aix-viewer__controls-inner__right">
                    <div class="aix-viewer__controls-label">
                        Page <span class="aix-viewer__pager-white">1 / </span>1
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>
