import {
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    output,
    viewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { handleCaret } from '@trade-platform/ui-utils';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-input',
    styleUrls: ['./aix-input.component.scss'],
    templateUrl: './aix-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixInputComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixInputComponent implements ControlValueAccessor {
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = model<boolean>(false);
    name = input<string>('');
    value = model<string>('');
    placeholder = input<string>('');
    hint = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');

    @HostBinding('attr.aix-control')
    aixControl: string;

    private caretPositionStart: number;
    private caretPositionEnd: number;
    private keyDownEvent: KeyboardEvent;
    private isTyping = false;

    constructor() {}

    onKeyDown(e: KeyboardEvent & { target: any }) {
        this.caretPositionStart = e.target.selectionStart;
        this.caretPositionEnd = e.target.selectionEnd;
        this.keyDownEvent = e;
        this.isTyping = true;
    }

    valueToSend(rawValue: string) {
        this.value.set(rawValue ?? null);

        if (this.isTyping) {
            handleCaret(
                [],
                this.keyDownEvent,
                (<ElementRef>this.inputField()).nativeElement as HTMLInputElement,
                this.caretPositionStart,
                this.caretPositionEnd
            );
        }

        return rawValue;
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = this.valueToSend((evt.target as HTMLInputElement).value);
        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }
        this._propagateChanges(val);
        this.valueChanges.emit(val);
    }

    onBlurInput(evt: Event | ClipboardEvent) {
        this.isTyping = false;
        const rawValue = (evt.target as HTMLInputElement).value;
        if (this.isDirty()) {
            const val = this.valueToSend(rawValue);
            this._propagateChanges(val);
        }
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
