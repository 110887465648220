import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    input,
    model,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    viewChild,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseOrder, DocumentFile } from '@trade-platform/ui-shared';
import { Subscription } from 'rxjs';
import { commentTag, orderStatus } from '@trade-platform/lib-enums';
import { AixFileUploadComponent } from '../../../../file-upload/file-upload';
import {
    BaseOrdersStoreFacade,
    getRequiredFilesToUpload,
    getUnfinishedRequiredUploads,
    getUnlabeledSupplementalUploads,
    getUploadedSupplementalFiles,
    OrderFormCommentsPipe,
    ORDERS_STORE_FACADE
} from '@advisor-ui/orders';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixModalComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { AixFormlistComponent, FormListItem } from '../../formlist/formlist';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-order-submit-confirmation',
    templateUrl: './submit-confirmation.html',
    styleUrls: ['./submit-confirmation.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixDataTestingDirective,
        AixHeaderSectionComponent,
        AixFormlistComponent,
        AixButtonComponent,
        AixFileUploadComponent,
        AixModalComponent,
        AsyncPipe,
        OrderFormCommentsPipe,
        AixNotificationComponent
    ]
})
export class AixOrderSubmitConfirmationComponent implements OnInit, OnChanges, OnDestroy {
    orderFileUpload = viewChild<AixFileUploadComponent>('orderFileUpload');
    submitOrderModal = viewChild.required<AixModalComponent>('submitOrderModal');
    manualSubmissionModal = viewChild.required<AixModalComponent>('manualSubmissionModal');
    notificationRef = viewChild<AixNotificationComponent>('notificationRef');

    isSubmitted = model<boolean>();
    isReadOnly = input<boolean>(false);
    approvalType = input<'electronicSubmission' | 'pendingManualSubmission' | 'taSubmission'>();

    showPdf = output<void>();
    onDownloadTradePackage = output();

    isSending = false;
    isSubmitDisabled = false;
    showSupportingDocuments = false;
    unfinishedRequiredUploads = false;

    error: string | null = null;

    subscriptions: Subscription[] = [];

    commentTag = commentTag;

    literals = {
        electronicSubmission: {
            title: `This order has been eSigned. Please finalize and submit the order.`,
            titleWetSign: `This order has been Downloaded & Signed. Please finalize and submit the order.`,
            completedError: `There was an error sending the order for reviewal. Please try again.`,
            buttonText: `Submit`
        },
        taSubmission: {
            title: `This order has been eSigned. Please finalize and submit the order.`,
            titleWetSign: `This order has been Downloaded & Signed. Please finalize and submit the order.`,
            completedError: `There was an error sending the order for reviewal. Please try again.`,
            buttonText: `Submit`
        },
        pendingManualSubmission: {
            title: `This order has been eSigned. Please download order and submit according to your firm's processes.`,
            titleWetSign: `Please download and have the paperwork signed and submitted based on your firm’s processes.`,
            completedError: `There was an error completing the order. Please try again.`,
            buttonText: `Submit Offline`
        }
    };

    uploadDocumentsButtonType = BUTTON_TYPE.secondary;
    showConfirmationButtonType = BUTTON_TYPE.primary;

    readonly orderStatus = orderStatus;

    orderNumber = '';

    constructor(
        private ref: ChangeDetectorRef,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        public router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.actions.submit.success$.subscribe(action => {
                this.isSubmitted.set(true);
                if (this.approvalType() === 'pendingManualSubmission') {
                    this.onDownloadTradePackage.emit();
                }
                this.ref.detectChanges();
                this.navigateToDetail();
            }),
            this.store.actions.approveStart.success$.subscribe(action => {
                this.isSubmitted.set(true);
                this.ref.detectChanges();
                this.navigateToDetail();
            }),
            this.store.actions.getOrder.success$.subscribe(() => {
                this.notificationRef()?.closeNotification();
                const order: BaseOrder = this.store.order;
                this.orderNumber = order.orderNumber.toString(10);
                this.checkOrder(order);
            }),
            this.store.actions.removeDocument.success$.subscribe(() => {
                const order: BaseOrder = this.store.order;
                this.checkOrder(order);
            })
        );

        const ord: BaseOrder = this.store.order;
        this.checkOrder(ord);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isSubmitted && changes.isSubmitted.currentValue) {
            this.navigateToDetail();
        }
    }

    navigateToDetail() {
        this.router.navigate(this.store.routes.detailOverview(this.store.order.id));
    }

    showConfirmation() {
        if (this.isSubmitDisabled || this.store.fileUpload.uploading) {
            this.notificationRef()?.openNotification();
        } else {
            if (
                this.store.order &&
                this.store.order.status === orderStatus.pendingManualSubmission
            ) {
                this.manualSubmissionModal().openModal();
            } else {
                this.submitOrderModal().openModal();
            }
        }
    }

    submitOrderModalSelected(option: string) {
        if (option === 'Yes, continue') {
            this.submitOrder();
        } else {
            this.submitOrderModal().closeModal();
        }
    }

    goToForm(item?: FormListItem) {
        this.router.navigate(
            this.store.routes.documentViewer(this.store.order.id, item?.document?.id as string)
        );
    }

    checkOrder(order: BaseOrder) {
        if (order) {
            this.unfinishedRequiredUploads = getUnfinishedRequiredUploads(order) > 0;
            this.showSupportingDocuments =
                !!getUploadedSupplementalFiles(order).length ||
                !!getRequiredFilesToUpload(order).length;

            this.isSubmitDisabled =
                getUnfinishedRequiredUploads(order) > 0 ||
                getUnlabeledSupplementalUploads(order) > 0;

            this.ref.detectChanges();
        }
    }

    uploadDocuments() {
        this.orderFileUpload()?.uploadDocuments();
    }

    removeDocument(file: DocumentFile) {
        const orderId = this.store.order.id;
        this.store.actions.removeDocument.dispatch({
            orderId,
            fileToRemove: file
        });
    }

    submitOrder() {
        const orderId = this.store.order.id;
        this.error = null;
        this.isSending = true;
        if (
            this.approvalType() === 'taSubmission' ||
            this.approvalType() === 'pendingManualSubmission'
        ) {
            this.store.actions.submit.dispatch({ orderId: orderId });
        } else {
            this.store.actions.approveStart.dispatch({ orderId: orderId });
        }
    }

    onClickViewPdf() {
        this.showPdf.emit();
    }

    onClickGoToOrders() {
        this.router.navigate(this.store.routes.status());
    }

    ngOnDestroy() {
        this.store.actions.approveStart.reset();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
