import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ProductExploreRecord } from '../../models/product/model';

@Component({
    selector: 'aix-product-image-card',
    templateUrl: './product-image-card.html',
    styleUrls: ['./product-image-card.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective]
})
export class AixProductCardImageComponent {
    product: Signal<ProductExploreRecord> = computed(() => {
        return this.productCard() as ProductExploreRecord;
    });
    fundStructure = '';

    productCard = input<ProductExploreRecord>();

    cardSelected = output<ProductExploreRecord>();

    viewMoreButtonType = BUTTON_TYPE.primary;

    onCardClick(card: ProductExploreRecord) {
        this.cardSelected.emit(card);
    }
}
