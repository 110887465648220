<section class="-no-padding">
    <aix-header-section [title]="label()">
        @if (!file().src) {
        <aix-button
            [ngStyle]="styles().links"
            [data-testing]="'upload-button'"
            (click)="showImageUploader = true"
            [icon]="'fa-upload'"
            [buttonType]="uploadButtonType"
            [buttonLabel]="'Upload'"
            [isDisabled]="showImageUploader"
        >
        </aix-button>
        } @if (file().src) {
        <aix-inline-dropdown
            class="u-ml16 u-mt8"
            [disabled]="showImageUploader"
            [dropdownOptions]="['Replace']"
            [label]="'Manage'"
            [styles]="styles()"
            (optionSelected)="onOptionSelected($event)"
        >
        </aix-inline-dropdown>
        }
    </aix-header-section>
    <div class="u-full-width">
        <div class="file-upload-container" [hidden]="!showImageUploader">
            <div class="flex-between-center">
                <span class="u-font-size--16 u-fw500">Upload</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="showImageUploader = false"
                ></span>
            </div>
            <aix-upload-document
                #imageDocumentsRef
                [multiple]="false"
                [fileSizeLimitUnit]="fileSizeLimitUnit()"
                [fileMinWidth]="fileMinWidth()"
                [fileMinHeight]="fileMinHeight()"
                [fileMaxWidth]="fileMaxWidth()"
                [fileMaxHeight]="fileMaxHeight()"
                [fileSizeLimit]="fileSizeLimit()"
                [orientationLimit]="file().orientationLimit"
                [showCloseUploaderButton]="false"
                [accept]="file().acceptedFileTypes"
                [acceptedTypeDisplayNames]="file().acceptedTypeDisplayNames"
                (filesSelected)="onImageSelected($event)"
            >
            </aix-upload-document>
        </div>

        <p>{{ infoText() }}</p>
        <ul class="aix-upload-requirements u-mt16">
            <li class="u-mt8">
                <span class="u-fw500">Min height or width:</span> {{ math.max(fileMinWidth(),
                fileMinHeight()) }} pixels
            </li>
            <li class="u-mt8">
                <span class="u-fw500">Max height or width:</span> {{ math.min(fileMaxWidth(),
                fileMaxHeight()) }} pixels
            </li>
            <li class="u-mt8">
                <span class="u-fw500">Max file size:</span> {{ convertFileSize(fileSizeLimit()) }} {{
                fileSizeLimitUnit() }}
            </li>
        </ul>

        @if (label() === 'Platform Favicon') {
        <div class="u-mt24">
            <h5 class="u-mb8">Preview</h5>

            <div class="flex-left-center">
                <span
                    class="aix-upload-info-box br-secondary__background-color flex-left-center u-mr8"
                    [ngClass]="infoBoxCustomClass()"
                >
                    @if (file().safeSrc !== null) {
                    <img [src]="file().safeSrc" />
                    }
                </span>
                @if (!(file().safeSrc !== null)) {
                <span> Please upload an image to see preview. </span>
                }
            </div>
        </div>
        } @if (label() !== 'DocuSign Email Logo' && label() !== 'DocuSign Document Logo' && label() !==
        'Platform Favicon') {
        <div class="u-mt24">
            <h5 class="u-mb8">Preview</h5>

            <div
                [ngStyle]="{ backgroundColor: primaryColor() }"
                [ngClass]="infoBoxCustomClass()"
                class="aix-upload-info-box br-secondary__background-color flex-left-center"
            >
                @if (!(file().src !== null)) {
                <span class="u-pt20 u-pb20"> Please upload an image to see preview. </span>
                } @if (file().safeSrc !== null) {
                <img [src]="file().safeSrc" />
                }
            </div>
        </div>
        } @if (label() === 'DocuSign Email Logo' || label() === 'DocuSign Document Logo') {
        <div class="u-mt24">
            <h5 class="u-mb8">Preview</h5>

            <div class="aix-upload-info-box aix-upload-info-box__email flex-left-center">
                @if (!(file().safeSrc !== null)) {
                <span> Please upload an image to see preview. </span>
                } @if (file().safeSrc !== null) {
                <img [src]="file().safeSrc" />
                }
            </div>
        </div>
        }
    </div>
</section>
