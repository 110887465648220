import {
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    viewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import {
    getMaskPositions,
    handleCaret,
    handleDecimal,
    handleNegatives,
    isSafari,
    keyboardEventSwitch,
    objectHasValue,
    unMaskNumericalValue
} from '@trade-platform/ui-utils';
import { isNumber, parseInt, subtract } from 'lodash-es';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-number',
    styleUrls: ['./aix-number.component.scss'],
    templateUrl: './aix-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixNumberComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixNumberComponent
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixNumberComponent implements ControlValueAccessor, Validator, OnChanges {
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    placeholder = input<string>('');
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = model<boolean>(false);
    allowCommas = input<boolean>(false);
    allowNegative = input<boolean>(true);
    allowDecimal = input<boolean>(false);
    allowLeadingZeroes = input<boolean>(false);
    decimalLimit = input<number>(2);
    hint = input<string>('');

    initialValue = model<string>();

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');

    @HostBinding('attr.aix-control')
    aixControl: string;

    private caretPositionStart: number;
    private caretPositionEnd: number;
    private keyDownEvent: KeyboardEvent;
    private maskPositions: number[] = [];
    private isTyping = false;

    onKeyDownKeyMatcher = keyboardEventSwitch({
        ArrowUp: (event: KeyboardEvent & { target: any }) => {
            if (!this.allowLeadingZeroes()) {
                this.isTyping = false;
                const val = parseInt(
                    this.allowCommas()
                        ? event.target.value.replaceAll(',', '')
                        : event.target.value,
                    10
                );
                const dec = event.target.value.split('.')[1] || 0;

                if (val !== null && !isNaN(val)) {
                    event.target.value = `${(val + 1).toString()}${
                        this.allowDecimal() && this.decimalLimit() > 0 ? '.' + dec.toString() : ''
                    }`;
                    this.onUserInput(event);
                } else {
                    event.preventDefault();
                }
            }
        },
        ArrowDown: (event: KeyboardEvent & { target: any }) => {
            if (!this.allowLeadingZeroes()) {
                this.isTyping = false;
                const val = parseInt(
                    this.allowCommas()
                        ? event.target.value.replaceAll(',', '')
                        : event.target.value,
                    10
                );
                const dec = event.target.value.split('.')[1] || 0;

                if (val !== null && !isNaN(val) && val > 0) {
                    event.target.value = `${subtract(val, 1).toString()}${
                        this.allowDecimal() && this.decimalLimit() > 0 ? '.' + dec.toString() : ''
                    }`;
                    this.onUserInput(event);
                } else {
                    event.preventDefault();
                }
            }
        }
    });

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            if (this.initialValue() && this.initialValue() === 'number') {
                this.initialValue.set(this.initialValue()?.toString());
            }
            this.valueToSend(this.initialValue() || '');
        }
    }

    onKeyDown(e: KeyboardEvent & { target: any }) {
        this.caretPositionStart = e.target.selectionStart;
        this.caretPositionEnd = e.target.selectionEnd;
        this.onKeyDownKeyMatcher.withEvent(e);
        this.keyDownEvent = e;
        this.isTyping = true;
    }

    valueToSend(rawValue: string, isBlur = false) {
        rawValue = isNumber(rawValue) ? (rawValue as number).toString() : rawValue;
        if (rawValue === '') {
            (<ElementRef>this.inputField()).nativeElement.value = rawValue;
            return rawValue;
        }
        let decimalHandled;
        if (this.allowDecimal() && this.decimalLimit() > 0) {
            decimalHandled =
                handleDecimal(rawValue, this.allowDecimal(), this.decimalLimit() || 2) || '';
        } else {
            decimalHandled = unMaskNumericalValue(rawValue);
        }

        const result: string[] = (rawValue ? ('' + rawValue).replace(',', '') : '').split('');
        const negative = this.allowNegative() && result[0] === '-';

        const decimal = decimalHandled.split('.')[1] ? '.' + decimalHandled.split('.')[1] : '';
        let whole = decimalHandled.split('.')[0];

        if (this.allowCommas()) {
            const wholeChunks = [];
            const wholeArray = [...whole];

            for (
                let i = wholeArray ? wholeArray.length % 3 : 0;
                wholeArray && wholeArray.length > 0;
                i--
            ) {
                let chunk;
                if (wholeArray.length >= 3) {
                    chunk = wholeArray.splice(wholeArray.length - 3);
                } else {
                    chunk = wholeArray.splice(0, 3);
                }
                wholeChunks.unshift(chunk);
            }
            whole = wholeChunks.reduce((acc, curr) => {
                return acc !== '' ? acc + ',' + curr.join('') : curr.join('');
            }, '');
        }

        let formattedNumber = whole + decimal;

        if (this.allowNegative() && negative && !formattedNumber.includes('-')) {
            formattedNumber = '-' + formattedNumber;
        } else if (formattedNumber.includes('-') && !this.allowNegative()) {
            formattedNumber = formattedNumber.replace('-', '');
        }

        (<ElementRef>this.inputField()).nativeElement.value = formattedNumber;

        const newPositions = getMaskPositions(
            objectHasValue(formattedNumber) ? formattedNumber : ''
        );
        const addedMaskElement = newPositions.length - this.maskPositions.length;
        this.maskPositions = newPositions;

        if ((this.allowDecimal() || this.allowCommas()) && (this.isTyping || isSafari) && !isBlur) {
            handleCaret(
                this.maskPositions,
                this.keyDownEvent,
                (<ElementRef>this.inputField()).nativeElement as HTMLInputElement,
                Math.max(this.caretPositionStart + addedMaskElement, 0),
                this.caretPositionEnd + addedMaskElement
            );
        }

        return formattedNumber && formattedNumber.length ? formattedNumber : '';
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = this.valueToSend((evt.target as HTMLInputElement).value || '');

        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }

        this._propagateChanges(handleNegatives(val));
        this.valueChanges.emit(handleNegatives(val));
    }

    onBlurInput(evt: Event | ClipboardEvent) {
        if (this.isDirty()) {
            const val = this.valueToSend(
                handleNegatives((evt.target as HTMLInputElement).value || ''),
                true
            );
            this._propagateChanges(val);
            this.valueChanges.emit(val);
        }
    }

    validate({ value }: UntypedFormControl) {
        value = isNumber(value) ? value.toString() : value;
        if (this.allowLeadingZeroes()) {
            return !value || value?.match(/^\d*(\.\d+)?$/g) ? null : { invalid: true };
        }
        return !value || value?.match(/^(?!0\d)\d*(\.\d+)?$/g) ? null : { invalid: true };
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
