import { ComponentRef, Directive, input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldConfigDecorationConfig } from '@trade-platform/form-fields';

export interface FieldDecoration {
    params: NonNullable<FieldConfigDecorationConfig['decoration']>['params'];
    hostObservables: HostObservables;
}

type HostObservables = {
    hasFocus: BehaviorSubject<boolean>;
};

@Directive({
    selector: '[aixFieldDecoration]',
    standalone: true
})
export class FieldDecorationDirective implements OnInit {
    params = input.required<FieldDecoration['params']>();
    decoration = input.required<Type<FieldDecoration>>();

    /**
     * If the host dynamic component needs to expose something, it has to be done via observables.
     *
     * @example
     * hostObservables.hasFocus = new BehaviorSubject(false);
     */
    hostObservables = input.required<HostObservables>();

    private component: ComponentRef<FieldDecoration>;

    constructor(private container: ViewContainerRef) {}

    ngOnInit() {
        this.component = this.container.createComponent(this.decoration());
        this.component.instance.params = this.params();
        this.component.instance.hostObservables = this.hostObservables();
    }
}
