import { Component, forwardRef, input, OnDestroy, OnInit, output } from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { emailValidator } from '@trade-platform/ui-utils';
import { AixUiComponentsModule } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-additional-advisor-information',
    templateUrl: './additional-advisor-information.html',
    styleUrls: ['./additional-advisor-information.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdditionalAdvisorInformationComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AdditionalAdvisorInformationComponent
        }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, AixUiComponentsModule]
})
export class AdditionalAdvisorInformationComponent
    implements ControlValueAccessor, OnDestroy, OnInit
{
    isFinancial = input<boolean>();
    removeButton = input<boolean>();
    removeAdvisor = output<void>();

    _form: UntypedFormGroup;

    private _onChange: (value: any | null | undefined) => void;

    private _destroy$: Subject<void> = new Subject<void>();

    constructor(private _fb: UntypedFormBuilder) {}

    ngOnInit() {
        this._createFormGroup();
        this._setupObservables();
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    writeValue(value: any): void {}

    registerOnChange(fn: (v: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error("registerOnTouched not implemented");
    }

    setDisabledState(isDisabled: boolean): void {
        // TODO: implement this method
        // throw new Error("setDisabledState not implemented");
    }

    validate(ctrl: UntypedFormControl) {
        return this._form.valid ? null : { invalid: true };
    }

    onClickRemove() {
        this.removeAdvisor.emit();
    }

    private _createFormGroup() {
        if (this.isFinancial()) {
            this._form = this._fb.group({
                fullName: this._fb.control(null, Validators.required),
                crdNumber: this._fb.control(null, Validators.required),
                email: this._fb.control(null, [Validators.required, emailValidator()])
            });
        } else {
            this._form = this._fb.group({
                fullName: this._fb.control(null, Validators.required),
                crdNumber: this._fb.control(null, Validators.required),
                repCodes: this._fb.control(null, Validators.required),
                email: this._fb.control(null, [Validators.required, emailValidator()])
            });
        }
    }

    private _setupObservables() {
        this._form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
