import { Component, input, OnInit, output, viewChild } from '@angular/core';
import { DynamicColorStyle } from '@trade-platform/ui-utils';
import {
    AixUploadDocumentComponent,
    FileSizeLimitUnits,
    OrientationLimiters,
    ReplacePayload
} from '../upload-document/upload-document';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixInlineDropdownComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'aix-upload-image',
    templateUrl: 'upload-image.html',
    styleUrls: ['upload-image.scss'],
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        NgIf,
        AixButtonComponent,
        AixDataTestingDirective,
        NgStyle,
        AixInlineDropdownComponent,
        AixUploadDocumentComponent,
        NgClass
    ]
})
export class AixUploadImageComponent implements OnInit {
    imageDocumentsRef = viewChild.required<AixUploadDocumentComponent>('imageDocumentsRef');

    primaryColor = input<string>('');
    file = input<any>();
    label = input<string>();
    styles = input<DynamicColorStyle>({
        links: {
            color: null
        }
    });
    infoText = input<string>();
    infoBoxCustomClass = input<string>();
    uploadUrl = input<string>();
    type = input<string>();
    formId = input<string>();
    fileId = input<string>();
    isModal = input<boolean>(false);
    replacePayload = input<ReplacePayload>();
    multiple = input<boolean>(false);
    disabled = input<boolean>(false);
    accept = input<string>('image/jpg,image/jpeg,image/png,image/bmp'); //image default
    fileSizeLimit = input<number>(300000); // 300KB default (in bytes);
    fileSizeLimitUnit = input<FileSizeLimitUnits>('KB');
    fileMinWidth = input<number>(0); // in pixels;
    fileMinHeight = input<number>(0); // in pixels;
    fileMaxWidth = input<number>(0); // in pixels;
    fileMaxHeight = input<number>(0); // in pixels;
    orientationLimit = input<OrientationLimiters>('none'); // no orientation limit, by default;

    filesSelected = output<File[]>();
    optionSelected = output<any>();

    showImageUploader = false;
    math = Math;

    uploadButtonType = BUTTON_TYPE.link;

    constructor() {}

    ngOnInit() {
        return;
    }

    public setShowImageUploader(showImageUploader: boolean) {
        this.showImageUploader = showImageUploader;
    }

    onImageSelected(e: File[]) {
        this.filesSelected.emit(e);
        this.showImageUploader = false;
    }

    onOptionSelected(e: any) {
        this.showImageUploader = true;
    }

    convertFileSize(fileSize: number) {
        switch (this.fileSizeLimitUnit()) {
            case 'MB':
                return fileSize * 0.000001;
            case 'KB':
                return fileSize * 0.001;
            default:
                return fileSize;
        }
    }
}
