import {
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    OnInit,
    output,
    SimpleChanges,
    viewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ValidationErrors
} from '@angular/forms';
import {
    createDateMask,
    getDatePositions,
    handleCaret,
    isSafari,
    objectHasValue,
    unMaskDateValue,
    validateDateFactory
} from '@trade-platform/ui-utils';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-date',
    styleUrls: ['./aix-date.component.scss'],
    templateUrl: './aix-date.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixDateComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixDateComponent
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixDateComponent implements ControlValueAccessor, OnInit, OnChanges {
    _propagateChanges: (value: string | void) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = model<boolean>(false);
    format = input<'mmddyyyy' | 'mmdd' | 'mmyyyy' | 'yyyy' | null | undefined>();
    limit = input<number>(0);
    initialValue = input<string>('');
    hint = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');

    @HostBinding('attr.aix-control')
    aixControl: string;

    private caretPositionStart: number;
    private caretPositionEnd: number;
    private keyDownEvent: KeyboardEvent;
    private mask: ReturnType<typeof createDateMask>;
    private maskPositions: number[] = [];
    private isTyping = false;

    constructor() {}

    ngOnInit() {
        const format = this.config()?.format || this.format();
        this.setMask(format);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.format) {
            this.setMask(this.format());
        }

        if (changes.initialValue) {
            const val = this.valueToSend(this.initialValue());

            if (val) {
                this._propagateChanges(val);
                this.valueChanges.emit(val);
            }
        }
    }

    setMask(format: 'mmddyyyy' | 'mmdd' | 'mmyyyy' | 'yyyy' | null | undefined) {
        this.mask = createDateMask(format);
        if (
            format &&
            format !== 'mmddyyyy' &&
            format !== 'mmdd' &&
            format !== 'mmyyyy' &&
            format !== 'yyyy'
        ) {
            throw new Error(
                `Invalid format ${format} for the date.component. Only 'yyyy', 'mmdd', 'mmyyyy' and 'mmddyyyy' are supported`
            );
        }
    }

    onKeyDown(e: KeyboardEvent & { target: any }) {
        this.caretPositionStart = e.target.selectionStart;
        this.caretPositionEnd = e.target.selectionEnd;
        this.keyDownEvent = e;
        this.maskPositions = getDatePositions(e.target.value);
        this.isTyping = true;
    }

    valueToSend(rawValue: string) {
        const format = this.config()?.format || this.format();
        const unmaskedValue = unMaskDateValue(format, rawValue);
        const maskedValue = this.mask(unmaskedValue);

        (<ElementRef>this.inputField()).nativeElement.value = maskedValue;

        const newPositions = getDatePositions(objectHasValue(maskedValue) ? maskedValue : '');
        let addedMaskElement = newPositions.length - this.maskPositions.length;
        addedMaskElement = addedMaskElement < 0 ? 0 : addedMaskElement;

        if (this.isTyping || isSafari) {
            handleCaret(
                this.maskPositions,
                this.keyDownEvent,
                (<ElementRef>this.inputField()).nativeElement as HTMLInputElement,
                addedMaskElement > 0
                    ? newPositions[newPositions.length - 1]
                    : this.caretPositionStart,
                addedMaskElement > 0 ? newPositions[newPositions.length - 1] : this.caretPositionEnd
            );
        }

        return unmaskedValue && unmaskedValue.length ? unmaskedValue : '';
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const format = this.config()?.format || this.format();
        const val = this.valueToSend(
            this.mask(unMaskDateValue(format, (evt.target as HTMLInputElement).value))
        );
        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }

        this._propagateChanges(val);
        this.valueChanges.emit(val);
    }

    getFormatPlaceholder() {
        const format = this.config()?.format || this.format();
        switch (format) {
            case 'yyyy':
                return 'yyyy';
            case 'mmdd':
                return 'mm/dd';
            case 'mmyyyy':
                return 'mm/yyyy';
            case 'mmddyyyy':
            default:
                return 'mm/dd/yyyy';
        }
        return 'mm/dd/yyyy';
    }

    validate(control: UntypedFormControl) {
        const value: any = control.value;
        const format = this.config()?.format || this.format();
        const validationResult: ValidationErrors | null = validateDateFactory(format)(control);
        return !value || !validationResult ? null : { invalid: true };
    }

    writeValue(value: any) {
        return this.valueToSend(value);
    }

    registerOnChange(fn: (value: string | void) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
