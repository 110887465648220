import {
    Component,
    effect,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    viewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    createNumericalMask,
    getMaskPositions,
    handleCaret,
    handleDecimal,
    handleNegatives,
    isSafari,
    objectHasValue,
    unMaskFloatValue
} from '@trade-platform/ui-utils';
import { isNumber } from 'lodash-es';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-currency',
    styleUrls: ['./aix-currency.component.scss'],
    templateUrl: './aix-currency.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixCurrencyComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixCurrencyComponent implements ControlValueAccessor, OnChanges {
    _propagateChanges: (value: string | null | void) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = model<boolean>(false);
    initialValue = input<string | null>();
    hint = input<string>('');
    config = input<any>({
        allowDecimal: true,
        decimalLimit: 2,
        required: true
    });

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');

    @HostBinding('attr.aix-control')
    aixControl: string;

    private caretPositionStart: number;
    private caretPositionEnd: number;
    private keyDownEvent: KeyboardEvent;
    private mask: ReturnType<typeof createNumericalMask>;
    private maskPositions: number[] = [];
    private isTyping = false;

    constructor() {
        this.setMask();
        effect(() => {
            if (this.config()) {
                this.setMask();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config) {
            this.setMask();
        }

        if (changes.initialValue) {
            const val = this.valueToSend(this.initialValue());

            if (val) {
                this._propagateChanges(handleNegatives(val as string));
                this.valueChanges.emit(handleNegatives(val as string));
            }
        }
    }

    setMask() {
        this.mask = createNumericalMask(
            '$',
            'start',
            this.config().allowDecimal !== false
                ? (this.config().decimalLimit || 0) > 0
                    ? this.config().decimalLimit
                    : 2
                : 0,
            this.config().allowNegative ?? false
        );
    }

    onKeyDown(e: KeyboardEvent & { target: any }) {
        this.caretPositionStart = e.target.selectionStart;
        this.caretPositionEnd = e.target.selectionEnd;
        this.keyDownEvent = e;
        this.maskPositions = getMaskPositions(objectHasValue(e.target.value) ? e.target.value : '');
        this.isTyping = true;
    }

    valueToSend(rawValue: string | number | null | undefined, isBlur = false) {
        if (rawValue === null || rawValue === undefined) {
            return null;
        }

        const decimalHandled = handleDecimal(
            rawValue,
            this.config().allowDecimal, // Default value is true
            (this.config().decimalLimit || 0) > 0 ? this.config().decimalLimit : 2 // Default value is 2
        );

        let unmaskedValue = unMaskFloatValue(decimalHandled);
        let maskedValue = this.mask(unmaskedValue);

        const arr = isNumber(rawValue) ? rawValue.toString(10).split('') : rawValue?.split('');

        const result: string[] = arr?.filter(r => {
            return r !== '$';
        });
        const negative = this.config().allowNegative && result && result[0] === '-';

        if (negative) {
            maskedValue = maskedValue === '' ? '$' : maskedValue;
            const splittedValue = maskedValue.split('');
            splittedValue.splice(1, 0, '-');
            maskedValue = splittedValue.join('');
        }

        unmaskedValue = negative ? ['-'].concat(unmaskedValue).join('') : unmaskedValue;
        (<ElementRef>this.inputField()).nativeElement.value = maskedValue;

        const newPositions = getMaskPositions(objectHasValue(maskedValue) ? maskedValue : '');
        const addedMaskElement = newPositions.length - this.maskPositions.length;

        if ((this.isTyping || isSafari) && !isBlur) {
            handleCaret(
                this.maskPositions,
                this.keyDownEvent,
                (<ElementRef>this.inputField()).nativeElement as HTMLInputElement,
                Math.max(this.caretPositionStart + addedMaskElement, 0),
                this.caretPositionEnd + addedMaskElement
            );
        }

        return unmaskedValue && unmaskedValue.length ? unmaskedValue : '';
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = this.valueToSend((evt.target as HTMLInputElement).value);

        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }

        this._propagateChanges(handleNegatives(val as string));
        this.valueChanges.emit(handleNegatives(val as string));
    }

    onBlurInput(evt: Event | ClipboardEvent) {
        if (this.isDirty()) {
            this.valueToSend(handleNegatives((evt.target as HTMLInputElement).value), true);
        }
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string | null | void) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
