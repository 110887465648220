import { Component, input, model, OnChanges, output, SimpleChanges } from '@angular/core';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface AixInlineSelectOption {
    label: string;
    value: unknown;
}

@Component({
    selector: 'aix-inline-select',
    templateUrl: 'aix-inline-select.component.html',
    styleUrls: ['aix-inline-select.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgStyle, NgIf, NgFor]
})
export class AixInlineSelectComponent implements OnChanges {
    value = model<unknown>();
    options = input.required<AixInlineSelectOption[]>();
    disabled = input<boolean>(false);
    styles = input<any>({
        links: {
            color: null
        }
    });

    selectedOption: AixInlineSelectOption | undefined;

    optionSelected = output<AixInlineSelectOption>();

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options) {
            if (this.options()) {
                this.selectedOption = this.options().find(item => item.value === this.value());
            }
        }
    }

    optionClicked(option: AixInlineSelectOption) {
        this.selectedOption = option;
        this.optionSelected.emit(option);
    }
}
