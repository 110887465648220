<div [formGroup]="commentTypeForm">
    <aix-radio-group
        [data-testing]="'commentType'"
        formControlName="commentType"
        [options]="commentTags"
        [labelField]="'title'"
        [valueField]="'value'"
        [descriptionField]="'description'"
        [isValid]="!!commentTypeForm.get('commentType')?.valid"
        [isRequired]="true"
        (valueChanges)="onCommentTagChanged($event)"
    >
    </aix-radio-group>

    @if (commentTypeForm.get('commentType')?.value === 'information') {
    <aix-checkbox
        [data-testing]="'commentPrivate'"
        formControlName="commentPrivate"
        class="u-ml28"
        [description]="'Private comments are only visible to members within your organization with the same Reviewer / Compliance role.'"
        (valueChanges)="onCommentPrivateChanged($event)"
    >
        Mark as Private
    </aix-checkbox>
    }
</div>
