import { AixRouteService, ProfileStoreFacade } from '@advisor-ui/app-services';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    viewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { orderStatus, orderStatusIndex } from '@trade-platform/lib-enums';
import {
    AixDataTestingDirective,
    AixLoadingComponent,
    AixNotificationComponent,
    BannerStylesDirective
} from '@trade-platform/ui-components';
import {
    AixErrorBoxComponent,
    AixExtendedNotification,
    AppState,
    BaseOrder,
    constants,
    DocumentViewerDownloadService,
    isFeatureEnabled,
    OrderFormComment
} from '@trade-platform/ui-shared';
import { getFromStorage, setToStorage } from '@trade-platform/ui-utils';
import { isInProgress, RemoteData } from 'ngx-remotedata';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { OrderFormCommentsService } from '../order-comments/order-form-comments.helper';
import {
    getDocumentIndexById,
    hasEntityApprovalPermission,
    hasReadOnlyAccess,
    isAdminAndCanEditOrder,
    userSeesOrderProcess
} from '../utils';
import { getAction, getFilesToDisplay } from './document-viewer/document-viewer.helper';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { AixDocumentTabsComponent } from './document-viewer/document-tabs/document-tabs';
import { AixDocumentViewerComponent } from './document-viewer/document-viewer';
import { AsyncPipe } from '@angular/common';
import { AixDocumentViewerActionBarComponent } from './document-viewer/document-action-bar/document-action-bar';

@Component({
    selector: 'aix-order-document-viewer',
    templateUrl: './order-document-viewer.html',
    styleUrls: ['./order-document-viewer.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixLoadingComponent,
        AixDocumentViewerActionBarComponent,
        AixErrorBoxComponent,
        AixNotificationComponent,
        AixDataTestingDirective,
        AixDocumentViewerComponent,
        AixDocumentTabsComponent,
        AsyncPipe
    ],
    hostDirectives: [BannerStylesDirective]
})
export class AixOrderDocumentViewerComponent implements OnInit, OnDestroy {
    actionsLoadingState$: Observable<boolean>;
    comments: OrderFormComment[];

    documentId: string;
    isReadOnly: boolean | undefined | null;
    hasComments: boolean | undefined | null;
    needsClarifications: boolean | undefined | null;
    needsChanges: boolean | undefined | null;

    styles = {
        documentControls: {},
        loading: {
            right: '3%'
        }
    };

    activeNotification: AixExtendedNotification = {
        text: '',
        status: 'alert'
    };

    subscriptions: Subscription[] = [];
    states: Observable<RemoteData<any, any>>[];

    canClose = false;

    readonly reducerSuffix = this.storeFacade.type;
    readonly notificationLanguage = {
        nigo: () => 'To resolve action items please replace this document with a corrected version.'
    };

    notification = viewChild<AixNotificationComponent>('notification');

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public commentsService: OrderFormCommentsService,
        public store: Store<AppState>,
        public profileStore: ProfileStoreFacade,
        public ref: ChangeDetectorRef,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private docDownloader: DocumentViewerDownloadService,
        private routeService: AixRouteService
    ) {
        this.states = [this.storeFacade.orderFormCommentsRemoteData$];
        this.actionsLoadingState$ = combineLatest([
            this.storeFacade.orderApproveRemoteData$,
            this.storeFacade.orderSignRemoteData$,
            this.storeFacade.orderMarkNigoRemoteData$,
            this.storeFacade.orderMarkNeedsClarificationRemoteData$,
            this.storeFacade.orderMakeChangesRemoteData$
        ]).pipe(map(states => states.some(isInProgress)));

        this.storeFacade.actions.documentViewerReset.dispatch();
        this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: 'Order' });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.pipe(first()).subscribe(params => {
                const orderId = params['orderId'];
                this.storeFacade.actions.getOrder.dispatch({
                    orderId,
                    reducerSuffix: this.reducerSuffix
                });
            }),
            this.route.params.subscribe(params => {
                const docId = params['documentId'];
                this.closeNotification();

                if (docId) {
                    this.documentId = docId;

                    const docState = this.storeFacade.documentViewer;
                    if (docState.initialized) {
                        this.onDocumentViewerInitialized();
                    }
                }
            }),
            this.storeFacade.orderSuccess$.subscribe(order => {
                const profile = this.profileStore.profile;

                const documentViewAction = getAction(order);
                this.storeFacade.actions.documentViewerSetAction.dispatch({
                    action: documentViewAction
                });

                const hasPermission = !!this.hasPermission(documentViewAction);
                this.storeFacade.actions.documentViewerSetPermissions.dispatch({
                    permissions: hasPermission
                });

                this.isReadOnly =
                    hasReadOnlyAccess(profile, order.firm.id) &&
                    orderStatusIndex[order.status] <
                        orderStatusIndex[orderStatus.pendingFirmApproval] &&
                    orderStatusIndex[order.status] !==
                        orderStatusIndex[orderStatus.pendingPresignatureReview];
            }),
            this.storeFacade.actions.documentViewerShowNotification.success$.subscribe(action => {
                this.openNotification(action.payload.notification);
            }),
            this.storeFacade.actions.needsClarification.success$.subscribe(action => {
                this.router.navigate(this.storeFacade.routes.status());
            }),
            this.storeFacade.actions.resumeApprovals.success$.subscribe(action => {
                this.router.navigate(this.storeFacade.routes.status());
            }),
            this.storeFacade.actions.markNigo.success$.subscribe(action => {
                this.router.navigate(this.storeFacade.routes.status());
            }),
            this.storeFacade.orderMakeChangesSuccess$.subscribe(order => {
                // If make changes api call successful, re-route to order process;
                if (!!this.storeFacade.order && order.id === this.storeFacade.order.id) {
                    this.onClose();
                    this.router.navigate(
                        this.storeFacade.routes.process.overview(this.storeFacade.order.id)
                    );
                }
            }),
            this.storeFacade.actions.documentViewerSetDocument.success$.subscribe(action => {
                const order = this.storeFacade.order;
                const docState = this.storeFacade.documentViewer;
                const filesToDisplay = docState.filesToDisplay;
                const index = getDocumentIndexById(filesToDisplay, action.payload.currentDocument);
                const currentFile = filesToDisplay[index];
                const currentForm = docState.currentForm;

                if (order && order.forms && docState && currentFile && currentFile.formId) {
                    const form = currentFile ? order.forms[currentFile.formId] : currentForm;
                    if (
                        form &&
                        form.isOnboarded === false &&
                        orderStatusIndex[order.status] <
                            orderStatusIndex[orderStatus.readyToSend] &&
                        order.status !== orderStatus.pendingPresignatureReview
                    ) {
                        this.onNotOnboarded(form.id);
                    }
                }
            }),
            this.storeFacade.actions.documentViewerSetInitialized.success$.subscribe(_ => {
                this.onDocumentViewerInitialized();
            }),
            this.storeFacade.actions.getFormComments.success$.subscribe(comments => {
                // Since there's a delayed data load, trigger not onboarded notification logic once comments load, if necessary;
                const docState = this.storeFacade.documentViewer;
                const order = this.storeFacade.order;
                const form =
                    docState.currentFile && docState.currentFile.formId
                        ? order.forms[docState.currentFile.formId]
                        : docState.currentForm;
                if (
                    form &&
                    form.isOnboarded === false &&
                    orderStatusIndex[order.status] < orderStatusIndex[orderStatus.readyToSend] &&
                    order.status !== orderStatus.pendingPresignatureReview
                ) {
                    this.onNotOnboarded(form.id);
                }

                this.comments = this.commentsService.getComments(form ? form.id : null);

                let reviewer = false;
                this.profileStore.profile.roles
                    .map(role => role.name)
                    .some(role => {
                        if (role === 'reviewer') {
                            reviewer = true;
                        }
                    });

                if (!reviewer && order.status !== orderStatus.completed) {
                    this.hasComments = false;
                    for (const comment of comments.payload) {
                        if (
                            (comment.children && comment.children.length > 0) ||
                            comment.commentType === 'UserGenerated'
                        ) {
                            const hasUnresolvedComments =
                                comment.status.toUpperCase() === 'UNRESOLVED';
                            const hasUnresolvedChildComments = comment.children.some(
                                (child: OrderFormComment) =>
                                    child.status.toUpperCase() === 'UNRESOLVED'
                            );
                            if (hasUnresolvedComments) {
                                if (
                                    comment.commentTag === 'clarification' &&
                                    hasUnresolvedChildComments
                                ) {
                                    this.hasComments = true;
                                    this.needsClarifications = true;
                                    this.closeNotification();
                                } else if (comment.commentTag === 'clarification') {
                                    this.hasComments = false;
                                    this.needsClarifications = false;
                                    break;
                                }
                                if (comment.commentTag === 'change') {
                                    this.needsChanges = true;
                                }
                            }
                        }
                    }
                }
            })
        );

        this.cachePreviousUrl();
    }

    cachePreviousUrl() {
        const previousUrl = this.routeService.previousRoute;
        if (previousUrl && !/document-viewer/.test(previousUrl)) {
            setToStorage('viewerCloseUrl', previousUrl);
        }
    }

    onDocumentViewerInitialized() {
        this.storeFacade.actions.documentViewerSetDocument.dispatch({
            currentDocument: this.documentId
        });

        const order = this.storeFacade.order;
        const filesToDisplay = getFilesToDisplay(order);
        const index = getDocumentIndexById(filesToDisplay, this.documentId);
        if (filesToDisplay && filesToDisplay.length > 0 && filesToDisplay[index]) {
            this.storeFacade.actions.documentViewerSetFile.dispatch({
                currentFile: filesToDisplay[index]
            });
        }
    }

    hasPermission(documentViewAction: string) {
        const order = this.storeFacade.order;
        const profile = this.profileStore.profile;
        const roles = profile ? profile.roles.map(role => role.name) : [];

        switch (documentViewAction) {
            case 'sign':
            case 'wetSign':
                return (
                    roles.indexOf(constants.profileTypes.REVIEWER) > -1 &&
                    roles.indexOf(constants.profileTypes.SIGNER) > -1 &&
                    hasEntityApprovalPermission(order.currentApproval, profile)
                );
            case 'approve':
                return (
                    roles.indexOf(constants.profileTypes.REVIEWER) > -1 &&
                    roles.indexOf(constants.profileTypes.AUTHORIZER) > -1 &&
                    hasEntityApprovalPermission(order.currentApproval, profile)
                );
            case 'signed':
            case 'resolve':
                const isValidRole =
                    roles.indexOf(constants.profileTypes.ADMIN) > -1 ||
                    roles.indexOf(constants.profileTypes.ACTIVE_INVESTOR) > -1 ||
                    roles.indexOf(constants.profileTypes.ADVISOR) > -1 ||
                    roles.indexOf(constants.profileTypes.ASSISTANT) > -1;

                // permission to edit submitted orders
                if (
                    (roles.indexOf(constants.profileTypes.REVIEWER) > -1 || isValidRole) &&
                    order.status === orderStatus.submitted &&
                    isFeatureEnabled('editSubmittedOrders')
                ) {
                    return true;
                }

                if (
                    order.status === orderStatus.submitted &&
                    !isFeatureEnabled('editSubmittedOrders')
                ) {
                    return false;
                }

                // It's an investor, advisor or assistant and the order is Under Review
                if (
                    isValidRole &&
                    order.status !== orderStatus.pendingPresignatureReview &&
                    orderStatusIndex[order.status] >=
                        orderStatusIndex[orderStatus.pendingFirmApproval] &&
                    orderStatusIndex[order.status] !== orderStatusIndex[orderStatus.nigo] &&
                    orderStatusIndex[order.status] !==
                        orderStatusIndex[orderStatus.needsClarification] &&
                    orderStatusIndex[order.status] !== orderStatusIndex[orderStatus.offline] &&
                    orderStatusIndex[order.status] !== orderStatusIndex[orderStatus.submitted] &&
                    !this.isRiaSubmission(order)
                ) {
                    return false;
                }

                if (
                    isAdminAndCanEditOrder(profile, order.firm.id) &&
                    (orderStatusIndex[order.status] === orderStatusIndex[orderStatus.nigo] ||
                        orderStatusIndex[order.status] ===
                            orderStatusIndex[orderStatus.needsClarification])
                ) {
                    return true;
                }

                return isValidRole;
            default:
                return false;
        }
    }

    isRiaSubmission(order: BaseOrder) {
        return (
            (order.status === orderStatus.pendingManualSubmission ||
                order.status === orderStatus.pendingSubmissionToTransferAgent) &&
            order.approvals?.length === 0
        );
    }

    onNotOnboarded(currentFormId: string) {
        this.closeNotification();

        const formComments = this.commentsService.getComments(currentFormId);
        if (formComments.length > 0) {
            this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: 'Comments' });
            this.storeFacade.actions.documentViewerShowNotification.dispatch({
                notification: {
                    text: this.notificationLanguage.nigo(),
                    status: 'warning'
                }
            });
        }
    }

    onClickDownload() {
        const order = this.storeFacade.order;
        this.docDownloader.getDocument({
            href: `${this.storeFacade.apiUrl}/${order.id}/download/`,
            name: order.title
        });
    }

    onClickDownloadDocument(fileId: string) {
        const order = this.storeFacade.order;
        const file = order.files.find(file => file.id === fileId);
        this.docDownloader.getDocument({
            href: `${this.storeFacade.apiUrl}/${order.id}/download/${fileId}`,
            name: file?.screenName || file?.name || ''
        });
    }

    onClose() {
        this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: 'Order' });
        this.closeNotification();
    }

    closeNotification() {
        if (this.notification()?.isOpen()) {
            this.activeNotification = {
                text: '',
                status: 'alert'
            };
            this.styles.documentControls = {};
            this.notification()?.closeNotification();
            this.ref.detectChanges();
        }
    }

    openNotification(notification: AixExtendedNotification) {
        this.activeNotification = notification;
        this.styles.documentControls = {
            bottom: '40px'
        };
        this.notification()?.openNotification();
        this.ref.detectChanges();
    }

    onCloseNotification() {
        this.styles.documentControls = {
            bottom: '0'
        };
    }

    onClickClose() {
        const closeUrl = getFromStorage('viewerCloseUrl') as string;
        if (closeUrl && !closeUrl.includes('access_token')) {
            this.router.navigateByUrl(closeUrl);
        } else {
            const order = this.storeFacade.order;
            const profile = this.profileStore.profile;

            if (userSeesOrderProcess(profile, order.status, order.repCode, order.firm.id)) {
                this.router.navigate(this.storeFacade.routes.process.overview(order.id));
            } else {
                this.router.navigate(this.storeFacade.routes.detailOverview(order.id));
            }
        }
    }

    returnOrder() {
        this.storeFacade.actions.documentViewerHideNotification.dispatch();
        const order = this.storeFacade.order;
        this.storeFacade.actions.resumeApprovals.dispatch({
            orderId: order.id
        });
    }

    editOrder() {
        this.storeFacade.actions.documentViewerHideNotification.dispatch();
        const order = this.storeFacade.order;
        this.storeFacade.actions.makeChanges.dispatch({ orderId: order.id });
    }

    ngOnDestroy() {
        this.storeFacade.actions.resetOrder.dispatch({ reducerSuffix: this.reducerSuffix });
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
