import {
    ChangeDetectionStrategy,
    Component,
    ChangeDetectorRef,
    output,
    viewChild
} from '@angular/core';
import { AixModalComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-cancel-changes-modal',
    templateUrl: './cancel-changes.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixModalComponent]
})
export class AixCancelChangesModalComponent {
    optionSelected = output<string>();

    cancelChangesModal = viewChild.required<AixModalComponent>('cancelChangesModal');

    constructor(private ref: ChangeDetectorRef) {}

    onCancelChangesModal(e: string) {
        this.optionSelected.emit(e);
    }

    public openModal() {
        this.cancelChangesModal().openModal();
        this.ref.markForCheck();
    }

    public closeModal() {
        this.cancelChangesModal().closeModal();
        this.ref.markForCheck();
    }
}
