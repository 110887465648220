<div class="{{ classNames() }}">
    <div class="buttonbar__menu secondary-navigation">
        @if (showTabs()) {
        <aix-button-bar
            #aixButtonBarRef
            class="u-full-width"
            [options]="tabs()"
            [ulClasses]="''"
            [liClasses]="'buttonbar__menu-item'"
            [selectedOption]="selectedOption"
            (onChange)="onChange($event)"
        >
            <ng-template let-option="option">
                <a
                    [routerLinkActive]="'active'"
                    [class.selected]="aixButtonBarRef.selectedOption() === option"
                    [class.br-primary__color]="aixButtonBarRef.selectedOption() === option"
                    [class.br-primary__border-top-color]="aixButtonBarRef.selectedOption() === option"
                    [class.u-color-tertiary-black]="aixButtonBarRef.selectedOption() !== option"
                >
                    {{ option.label }}
                </a>
            </ng-template>
        </aix-button-bar>
        }

        <div #tabsControlsRef class="tabs-controls" [hidden]="!showTabsControls">
            <ng-content select="[aixHeaderTabsControls]"></ng-content>
        </div>
    </div>
    <div class="header--page flex-col">
        <div class="u-full-width flex-between-center">
            <div class="-heading flex-col">
                @if (titleCaseLabel()) {
                <h2>
                    {{ (selectedOption?.title || selectedOption?.label || selectedTab()) | titlecase
                    }}
                </h2>
                } @if (!titleCaseLabel()) {
                <h2>{{ selectedOption?.title || selectedOption?.label || selectedTab() }}</h2>
                }
                <ng-content select="[aixHeaderTitle]"></ng-content>
            </div>

            <div class="-controls header-controls">
                <ng-content select="[aixHeaderControls]"></ng-content>
            </div>
        </div>
        <ng-content select="[aixHeaderTabsBody]" class="u-full-width"></ng-content>
    </div>
</div>
