import { Component, forwardRef, input, OnDestroy, OnInit, output, viewChild } from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Account } from '@trade-platform/ui-shared';
import { debounceTime } from 'rxjs/operators';
import {
    AixDataTestingDirective,
    AixDropdownComponent,
    AixNextPendingFieldDirective
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-account-registration',
    templateUrl: './account-registration.html',
    styleUrls: ['./account-registration.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixAccountRegistrationGroupComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixAccountRegistrationGroupComponent
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixDropdownComponent,
        AixDataTestingDirective,
        AixNextPendingFieldDirective
    ]
})
export class AixAccountRegistrationGroupComponent
    implements ControlValueAccessor, OnInit, OnDestroy
{
    accountDropdownRef = viewChild<AixDropdownComponent>('accountDropdownRef');

    isLoadingAccounts = input<boolean>();
    accounts = input<Account[]>();
    filtering = input<boolean>(false);

    addAccountDetails = output();
    selectAccount = output<any>();
    filterAccount = output<string>();

    form: UntypedFormGroup;

    isDestroying = false;
    isActionButtonSelected = false;
    subscriptions: Subscription[] = [];

    private debouncer: Subject<string> = new Subject<string>();
    private _onChange: (value: any | null | undefined) => void;

    constructor(private _fb: UntypedFormBuilder) {
        this._createFormGroup();
        this._setupObservables();
    }

    ngOnInit() {
        this.isDestroying = false;
        this.patchForm();
    }

    onFilterAccounts(event: string) {
        this.debouncer.next(event);
    }

    onClickDropdownAction() {
        this.isActionButtonSelected = this.accountDropdownRef()?.isActionButtonSelected || false;
        this.addAccountDetails.emit();
        this.form.get('accountSelected')?.clearValidators();
        this.form.get('accountSelected')?.updateValueAndValidity();
        this.form.updateValueAndValidity();
    }

    patchForm() {
        const data = {};

        this.form.patchValue(data);
    }

    validate(ctrl: UntypedFormControl) {
        if (this.accountDropdownRef()?.isActionButtonSelected) {
            return null;
        }

        return this.form.get('accountSelected')?.value?.length || this.isDestroying
            ? null
            : { invalid: true };
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            return;
        }
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            accountSelected: this._fb.control('', [Validators.required])
        });
    }

    private _setupObservables() {
        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => {
                this.isActionButtonSelected = false;
                if (value.accountSelected?.length) {
                    this.form.get('accountSelected')?.setValidators([Validators.required]);
                    this.selectAccount.emit(value.accountSelected);
                }
                if (this._onChange) {
                    this._onChange(value);
                }
            }),
            this.debouncer.pipe(debounceTime(300)).subscribe(val => this.filterAccount.emit(val))
        );
    }

    onBlurInvestorAccount() {
        this.form.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.isDestroying = true;
        this.form.updateValueAndValidity();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
