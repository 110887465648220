import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    viewChild,
    ViewEncapsulation
} from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import {
    BaseOrder,
    DocumentFile,
    OrderFormComment,
    PreSignatureReviewType
} from '@trade-platform/ui-shared';
import { DetailViewState } from '../../overview';
import { fileType, orderStatus } from '@trade-platform/lib-enums';
import { AixOrderStepComponent } from '../step/order-step';
import { AixOrderPreReviewComponent } from '../../pre-review/pre-review';
import { AixTemplatesComponent } from '@advisor-ui/app-components';
import { AsyncPipe } from '@angular/common';
import { AixPdfGenerationComponent } from '../../pdf-generation/pdf-generation';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixHeaderSectionComponent,
    AixModalComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { AixFormlistComponent } from '../../formlist/formlist';
import { AixFileUploadComponent } from '../../../../file-upload/file-upload';
import {
    AixRequiredFilesMenuOption,
    AixUploadedFilesMenuOption
} from '../../../../file-upload/filelist/filelist';
import { mergeMap } from 'rxjs/operators';
import { getFilesToDisplay } from '../../../../order-document-viewer/document-viewer/document-viewer.helper';
import { getRequiredFilesToUpload, getUploadedSupplementalFiles } from '../../utils/order-utils';

@Component({
    selector: 'aix-review-step',
    templateUrl: './review-step.html',
    styleUrls: ['./review-step.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixTemplatesComponent,
        AixPdfGenerationComponent,
        AixHeaderSectionComponent,
        AixFormlistComponent,
        AixDataTestingDirective,
        AixButtonComponent,
        AixFileUploadComponent,
        AixOrderPreReviewComponent,
        AixExpansionPanelComponent,
        AixModalComponent,
        AixNotificationComponent,
        AsyncPipe
    ]
})
export class AixReviewStepComponent extends AixOrderStepComponent implements OnInit, OnDestroy {
    orderFileUploadRef = viewChild<AixFileUploadComponent>('orderFileUploadRef');
    cancelPresignatureReviewModal = viewChild.required<AixModalComponent>(
        'cancelPresignatureReviewModal'
    );
    deleteModal = viewChild.required<AixModalComponent>('deleteModal');
    formsMustBeLabeledNotification = viewChild<AixNotificationComponent>(
        'formsMustBeLabeledNotification'
    );

    viewState: DetailViewState = 'preview';

    orderStatus = orderStatus;
    subscriptions: Subscription[] = [];

    arrayFiles: AixUploadedFilesMenuOption[] = [];
    arrayRequiredFiles: AixRequiredFilesMenuOption[] = [];
    uploadButtonType = BUTTON_TYPE.link;
    continueButtonType = BUTTON_TYPE.primary;
    sendButtonType = BUTTON_TYPE.primary;
    orderComments: OrderFormComment[];
    pollReviewsSubscription: Subscription | null;
    reviewCompleted = false;
    selectedReviewType: PreSignatureReviewType | null;
    inPreviewSend = false;
    filesGenerated = false;
    notOnboardedFormsCompleted = false;

    showStep = 0;
    readonly reducerSuffix = this.storeFacade.type;
    required = false;

    fileToRemove: DocumentFile;

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.actions.getOrder.success$.subscribe(action => {
                this.order = action.payload.order;

                this.required = this.order.presignatureReview?.required || false;

                switch (this.order.status) {
                    case orderStatus.readyForPresignatureReview:
                        this.showStep = 0;
                        break;
                    case orderStatus.pendingPresignatureReview:
                        this.showStep = 1;
                        break;
                    case orderStatus.presignatureReviewComplete:
                        if (
                            this.order.presignatureReview?.steps?.length === 0 ||
                            this.order.presignatureReview?.steps[0]?.status === 'not yet sent'
                        ) {
                            this.showStep = 2;
                        } else {
                            this.showStep = 3;
                        }
                        break;
                    default:
                        this.showStep = 0;
                }
                if (this.order.status === orderStatus.pendingPresignatureReview) {
                    this.startPolling(this.order);
                }

                const notOnboardedForms = Object.keys(this.order.forms).filter(
                    f => !this.order.forms[f].isOnboarded
                );
                this.notOnboardedFormsCompleted = notOnboardedForms.every(formId => {
                    return this.order.files.find(item => item.formId === formId);
                });

                this.ref.detectChanges();
            }),
            this.storeFacade.actions.orderStartReview.success$.subscribe(action => {
                this.storeFacade.actions.getOrder.dispatch({
                    orderId: this.orderId,
                    reducerSuffix: this.reducerSuffix
                });
            }),
            this.storeFacade.actions.orderReviewComplete.success$.subscribe(action => {
                this.storeFacade.actions.getOrder.dispatch({
                    orderId: this.orderId,
                    reducerSuffix: this.reducerSuffix
                });
            }),
            this.storeFacade.actions.orderCancelReview.success$.subscribe(action => {
                this.storeFacade.actions.getOrder.dispatch({
                    orderId: this.orderId,
                    reducerSuffix: this.reducerSuffix
                });
            })
        );

        this.order = this.storeFacade.order;
        if (this.order) {
            this.onOrderUpdated(this.order);
        }
    }

    startPolling(ord: BaseOrder) {
        // Stop polling if the order status or current step changes (via make changes);
        if (this.pollReviewsSubscription && this.reviewCompleted) {
            this.stopPolling();
        } else if (
            !this.pollReviewsSubscription &&
            ord.status === orderStatus.pendingPresignatureReview &&
            !this.reviewCompleted
        ) {
            // We need to poll the order and the polling is not running
            this.pollSignatures(ord);
        }
    }

    stopPolling() {
        if (this.pollReviewsSubscription) {
            this.pollReviewsSubscription.unsubscribe();
            this.pollReviewsSubscription = null;
        }
    }

    pollSignatures(ord: BaseOrder) {
        this.pollReviewsSubscription = interval(20000)
            .pipe(mergeMap(() => this.storeFacade.getOrder(ord.id) as Observable<BaseOrder>))
            .subscribe(order => {
                if (order.status === orderStatus.presignatureReviewComplete) {
                    this.reviewCompleted = true;
                    this.order = order;
                    this.stopPolling();
                }

                this.ref.detectChanges();
            });
    }

    viewOrder(docId: string | null = null) {
        const order = this.storeFacade.order;
        const filesToDisplay = getFilesToDisplay(order);
        const id = docId ? docId : filesToDisplay[0].id;

        this.router.navigate(this.storeFacade.routes.documentViewer(this.orderId, id));
    }

    goToForm(event: any) {
        this.router.navigate(
            this.storeFacade.routes.documentViewer(this.orderId, event.document.id)
        );
    }

    onOrderUpdated(order: BaseOrder): void {
        if (order) {
            if (order.files) {
                this.arrayFiles = getUploadedSupplementalFiles(order);
            }
            if (order.requiredSupplementalFiles) {
                this.arrayRequiredFiles = getRequiredFilesToUpload(order);
            }
            if (this.order.files.every(file => file.type !== fileType.unassignedForm)) {
                this.formsMustBeLabeledNotification()?.closeNotification();
            }
        }
        this.ref.detectChanges();
    }

    changeViewState(state: DetailViewState) {
        this.viewState = state;
    }

    // upload
    uploadDocuments() {
        this.orderFileUploadRef()?.uploadDocuments();
    }

    confirmReviewType() {
        if (this.canProceed()) {
            if (this.selectedReviewType && this.selectedReviewType === 'collect-signatures') {
                this.storeFacade.actions.orderStartReview.dispatch({
                    orderId: this.orderId,
                    skipReview: true
                });
            } else {
                this.inPreviewSend = true;
            }
        }
    }

    send() {
        if (this.canProceed()) {
            this.storeFacade.actions.orderStartReview.dispatch({
                orderId: this.orderId,
                skipReview: false
            });
        }
    }

    continue() {
        if (this.canProceed()) {
            this.storeFacade.actions.orderReviewComplete.dispatch({
                orderId: this.orderId,
                required: this.required
            });
        }
    }

    canProceed() {
        if (!this.order.files.every(file => file.type !== fileType.unassignedForm)) {
            this.formsMustBeLabeledNotification()?.openNotification();
            return false;
        }

        this.formsMustBeLabeledNotification()?.closeNotification();
        return true;
    }

    cancelReview() {
        if (this.order && this.order.status === orderStatus.readyForPresignatureReview) {
            this.inPreviewSend = false;
        } else {
            this.cancelPresignatureReviewModal().openModal();
        }
    }

    confirmCancelReview(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.storeFacade.actions.orderCancelReview.dispatch({ orderId: this.orderId });
                this.inPreviewSend = false;
                this.stopPolling();
                break;
            default:
                break;
        }
    }

    onSelectReviewMethod(type: PreSignatureReviewType) {
        this.selectedReviewType = type;
    }

    disableContinueButton() {
        if (!this.order) {
            return true;
        }
        if (this.order.status === orderStatus.readyForPresignatureReview) {
            if (this.selectedReviewType == null) {
                return true;
            }
            if (this.order?.fund?.isOnboarded === false) {
                for (const formId in this.order.forms) {
                    if (
                        this.order.forms[formId] &&
                        this.order.forms[formId].isOnboarded === false
                    ) {
                        if (!this.order.files.find(uploaded => uploaded.formId === formId)) {
                            return true;
                        }
                    }
                }
            }
        }

        if (this.orderFileUploadRef() && this.orderFileUploadRef()?.fileUploadOpen) {
            return true;
        }
        return false;
    }

    finishUpload() {
        this.storeFacade.actions.finalizeOrder.dispatch({ orderId: this.orderId });
    }

    removeDocument(file: DocumentFile) {
        this.fileToRemove = file;
        this.deleteModal().openModal();
    }

    onRemoveUploadOptionSelected(event: string) {
        switch (event) {
            case 'Yes, continue':
                this.storeFacade.actions.removeDocument.dispatch({
                    orderId: this.order.id,
                    fileToRemove: this.fileToRemove
                });
                break;
            case 'Cancel':
                this.deleteModal().closeModal();
                break;
        }
    }

    ngOnDestroy() {
        this.stopPolling();
        this.storeFacade.actions.sendDocusign.reset();
        super.ngOnDestroy();
    }
}
