<div class="flex-between-center">
    <aix-button
        (click)="cancelOrder()"
        [isDisabled]="isReadOnly() ?? false"
        [buttonType]="cancelButtonType"
        [buttonLabel]="'Cancel Order'"
    >
    </aix-button>

    <aix-button
        (click)="goToOverview()"
        [buttonType]="goToOverviewButtonType"
        [buttonLabel]="'Go to Overview'"
    >
    </aix-button>
</div>

<div class="step-form-row step-form-row__full step-form-row__title">
    <h3 class="order-process-title">Forms</h3>
    <span>Completed: {{ completedForms }} / {{ arrayForms.length }}</span>
</div>

@for (form of arrayForms; track form) {
<div
    [formGroup]="parentForm()"
    class="step-form-row step-form-row__full step-form-row__vertical selectable"
    [data-testing]="'form-link'"
    (click)="clickEvent(form.id)"
>
    <div class="step-form-row-container step-form-row__horizontal u-pt16 u-pb16">
        <p
            class="step-form-row-title step-form-row-item br-link"
            [class.step-form-row-item__selected]="selectedFormId === form.id"
        >
            <span class="step-form-row-title-text">{{ form.name }}</span>
        </p>
        @if (form.status === formStatus.completed) {
        <p class="step-action-items br-primary__color">
            <span class="icon fa-regular fa-check"></span>
        </p>
        }
    </div>

    <ng-template #orderFormCommentTitle>
        <div class="flex-center-center">
            <span class="u-warning-orange">
                <i class="fa-regular fa-triangle-exclamation"></i>
                Action Items
            </span>
        </div>
    </ng-template>
    <ng-template #orderFormCommentBody>
        <aix-order-form-comments
            [canAddComments]="false"
            [currentDocument]="selectedForm"
            [mode]="commentsMode"
            [comments]="comments()"
            [parentForm]="parentForm()"
        >
        </aix-order-form-comments>
    </ng-template>
    @if (selectedFormId === form.id && (comments() && comments().length > 0)) {
    <aix-expansion-panel
        [headerTemplate]="orderFormCommentTitle"
        [headerNgClass]="orderFormCommentsHeaderClass"
        [bodyTemplate]="orderFormCommentBody"
        [bodyNgClass]="orderFormCommentsBodyClass"
        [arrowPosition]="'left'"
        [isClosed]="isCommentsClosed"
        [isDisabled]="false"
    >
    </aix-expansion-panel>
    }
</div>
}
