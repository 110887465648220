import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    input,
    model,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    viewChild
} from '@angular/core';
import { DetailViewState } from '../overview';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixNotificationComponent,
    AixRadioGroupComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrder, eSignEnvelopeRecipient, OrderDeliveryType } from '@trade-platform/ui-shared';
import { idCheckType, recipientType } from '@trade-platform/lib-enums';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';

@Component({
    selector: 'aix-delivery',
    templateUrl: './delivery.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        AixNotificationComponent,
        FormsModule,
        ReactiveFormsModule,
        AixRadioGroupComponent,
        AixButtonComponent
    ]
})
export class AixOrderProcessDeliveryComponent implements OnInit, OnChanges, OnDestroy {
    notification = viewChild<AixNotificationComponent>('notification');
    order = input<BaseOrder>();
    isReadOnly = input<boolean>(false);
    wetSignOnly = model<boolean>(false);

    onMakeChanges = output();
    clickContinue = output<DetailViewState>();

    deliveryForm: UntypedFormGroup;
    deliveryMethod: OrderDeliveryType;
    deliveryMethods = [
        {
            value: 'docu-sign' as OrderDeliveryType,
            title: 'Send for eSign',
            description: 'Send documents for eSignature.',
            disabled: false
        },
        {
            value: 'wet-sign' as OrderDeliveryType,
            title: 'Download & Sign',
            description: 'Download and sign forms.',
            disabled: false
        }
    ];

    continueButtonType = BUTTON_TYPE.primary;

    subscriptions: Subscription[] = [];

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef,
        private _fb: UntypedFormBuilder
    ) {
        this.deliveryForm = this._fb.group({
            deliveryMethod: this._fb.control({}, Validators.required)
        });
    }

    ngOnInit() {
        if (this.wetSignOnly()) {
            this.deliveryForm.setValue({ deliveryMethod: 'wet-sign' });
            this.deliveryMethod = 'wet-sign';
            this.ref.detectChanges();
        } else {
            this.deliveryForm.setValue({ deliveryMethod: 'docu-sign' });
            this.deliveryMethod = 'docu-sign';
            this.ref.detectChanges();
        }

        this.subscriptions.push(
            this.deliveryForm.valueChanges.subscribe(() => {
                this.deliveryMethod = this.deliveryForm.value.deliveryMethod;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.order && changes.order.currentValue) {
            const order = this.order() as BaseOrder;
            if (
                order.eSign &&
                order.eSign.envelope &&
                order.eSign.envelope.idCheckConfig === idCheckType.sms &&
                order.eSign.envelope.recipients &&
                order.eSign.envelope.recipients.find(
                    (r: eSignEnvelopeRecipient) =>
                        r.type === recipientType.investor && !r.phoneMobile
                )
            ) {
                this.wetSignOnly.set(true);
                this.deliveryForm.get('deliveryMethod')?.setValue('wet-sign');
                this.notification()?.openNotification();
            }

            this.deliveryMethods.forEach(d => {
                d.disabled = d.value === 'docu-sign' && this.order()?.eSign?.isAvailable === false;
            });

            this.ref.detectChanges();
        }
    }

    onClickContinue() {
        this.clickContinue.emit(this.deliveryMethod);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
