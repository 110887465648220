@if (!isSubmitted()) {
<div>
    <div class="flex-between-top">
        <div class="submit-confirmation-title">
            <p class="order-step-content-subtitle" [data-testing]="'submit-confirmation-message'">
                @if (!store.order?.wetSign) {
                <span>{{ literals[approvalType()]?.title }}</span>
                } @if (store.order?.wetSign) {
                <span>{{ literals[approvalType()]?.titleWetSign }}</span>
                }
            </p>
        </div>
    </div>

    @if (orderNumber) {
    <aix-header-section [sectionClasses]="'u-mt24'" [title]="'Order #' + orderNumber">
    </aix-header-section>
    }

    <aix-formlist
        [isReadOnly]="isReadOnly()"
        [forms]="(store.orderSuccess$ | async)?.forms"
        [orderFormComments]="(store.orderFormCommentsSuccess$ | async) | orderFormCommentsFilter:false:false:undefined:commentTag.change"
        [title]="'Required Forms'"
        [clickable]="true"
        (clickElem)="goToForm($event)"
        (onRemove)="removeDocument($event)"
    >
    </aix-formlist>

    @if (showSupportingDocuments) {
    <div class="flex-between-center u-mt64" [data-testing]="'filelist-header'">
        <div class="-heading">
            <h5 [data-testing]="'upload-documents-title'">Supporting Documents</h5>
        </div>
    </div>
    } @if (!showSupportingDocuments) {
    <aix-notification
        #notificationRef
        [data-testing]="'submitNotification'"
        [isOpen]="false"
        [canClose]="true"
        status="warning"
        [classList]="['u-mt16']"
    >
        All supporting documents must be uploaded or ignored to continue.
    </aix-notification>
    <div class="aix-toolbar u-mt24">
        @if (!unfinishedRequiredUploads && this.store?.order?.status !==
        orderStatus.pendingManualSubmission) {
        <aix-button
            (click)="uploadDocuments()"
            [data-testing]="'upload-documents-button'"
            [isDisabled]="orderFileUpload.fileUploadOpen || isReadOnly()"
            [buttonType]="uploadDocumentsButtonType"
            [buttonLabel]="'Upload Documents'"
        >
        </aix-button>
        } @if (approvalType()) {
        <aix-button
            [data-testing]="'submit'"
            [isDisabled]="isReadOnly()"
            (click)="showConfirmation()"
            [buttonType]="showConfirmationButtonType"
            [buttonLabel]="literals[approvalType()]?.buttonText"
        >
        </aix-button>
        }
    </div>
    }

    <aix-file-upload
        class="u-block"
        [data-testing]="'supporting-documents-upload'"
        #orderFileUpload
        [enableIgnore]="true"
        [isReadOnly]="isReadOnly()"
        [displayComponentHeader]="false"
        [orderFileComments]="(store.orderFormCommentsSuccess$ | async) | orderFormCommentsFilter:false:false:undefined:commentTag.change"
    >
    </aix-file-upload>

    @if (showSupportingDocuments) {
    <aix-notification
        #notificationRef
        [data-testing]="'submitNotification'"
        [isOpen]="false"
        [canClose]="true"
        status="warning"
        [classList]="['u-mt16']"
    >
        All supporting documents must be uploaded or ignored to continue.
    </aix-notification>
    <div class="aix-toolbar u-mt24">
        @if (!unfinishedRequiredUploads && this.store?.order?.status !==
        orderStatus.pendingManualSubmission) {
        <aix-button
            (click)="uploadDocuments()"
            [data-testing]="'upload-documents-button'"
            [isDisabled]="orderFileUpload.fileUploadOpen || isReadOnly()"
            [buttonType]="uploadDocumentsButtonType"
            [buttonLabel]="'Upload Documents'"
        >
        </aix-button>
        } @if (literals[approvalType()]?.buttonText) {
        <aix-button
            [data-testing]="'submit'"
            [isDisabled]="isReadOnly()"
            (click)="showConfirmation()"
            [buttonType]="showConfirmationButtonType"
            [buttonLabel]="literals[approvalType()]?.buttonText"
        >
        </aix-button>
        }
    </div>
    } @if (error && !isSending) {
    <div class="flex-center-center u-mt24 submit-confirmation-error">
        <p>{{ literals[approvalType()]?.completedError }}</p>
    </div>
    }
</div>
}

<aix-modal
    #submitOrderModal
    modalType="confirmation"
    [modalTitle]="'Submit order?'"
    (optionSelected)="submitOrderModalSelected($event)"
>
    <ng-template>
        <p>This order will be submitted for processing.</p>
    </ng-template>
</aix-modal>

<aix-modal
    #manualSubmissionModal
    modalType="confirmation"
    [modalTitle]="'Submit order offline?'"
    (optionSelected)="submitOrderModalSelected($event)"
>
    <ng-template>
        <div class="order-detail-modal">
            <p class="u-fw500">This order will be downloaded and marked as offline.</p>
            <p>Please submit the order according to your firm's processes.</p>
        </div>
    </ng-template>
</aix-modal>
