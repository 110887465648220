import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    input,
    OnInit,
    Signal
} from '@angular/core';
import { NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-progress',
    templateUrl: './progress.html',
    styleUrls: ['./progress.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, AixDataTestingDirective]
})
export class AixProgressComponent implements OnInit {
    progressN: Signal<number> = computed(() => {
        return this.progress() !== undefined
            ? Math.floor(Math.max(this.progress() as number, 0))
            : 0;
    });

    progress = input<number>();
    type = input<'linear' | 'circular'>('linear');
    sqSize = input(120);
    strokeLinecap = input<'round' | 'butt'>('round');
    strokeWidth = input(8);
    fontSize = input(24);
    subtext = input(true);

    innerStrokeWidth: number;
    radius: number;
    viewBox: string;
    dashArray: number;
    dashOffset: number;
    subtextPosition: string;

    constructor(private cd: ChangeDetectorRef) {
        effect(() => {
            this.updateProgress();
            this.cd.detectChanges();
        });
    }

    ngOnInit() {
        this.updateProgress();
    }

    updateProgress() {
        this.innerStrokeWidth = this.strokeWidth();
        this.radius = (this.sqSize() - this.strokeWidth()) / 2;
        this.viewBox = `0 0 ${this.sqSize()} ${this.sqSize()}`;
        this.dashArray = this.radius * Math.PI * 2;
        this.dashOffset = this.dashArray - (this.dashArray * this.progressN()) / 100;
        this.subtextPosition = this.subtext()
            ? String(Math.ceil(this.sqSize() / 2 + this.fontSize() / 4.1667))
            : String(Math.ceil(this.sqSize() / 2 + this.fontSize() / 4.1667) + this.fontSize());
    }
}
