import { Directive, input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[aixTemplate]',
    standalone: true
})
export class AixTemplateDirective {
    name = input('', { alias: 'aixTemplate' });

    constructor(public template: TemplateRef<any>) {}
}
