import {
    Directive,
    ElementRef,
    HostBinding,
    input,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { isSuccess, RemoteData } from 'ngx-remotedata';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PlaceholderConfig {
    state$: Observable<RemoteData<any>[]>;
    color: string;
}

@Directive({
    selector: '[aixPlaceholder]',
    standalone: true
})
export class AixPlaceholderDirective implements OnChanges, OnDestroy {
    aixPlaceholderConfig = input.required<PlaceholderConfig>();

    @HostBinding('class')
    class = 'u-relative';

    isLoading$: Observable<boolean>;

    ePlaceholder: any;
    spawned = false;

    subscriptions: Subscription[] = [];

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.spawn();
    }

    despawn() {
        if (this.spawned && this.ePlaceholder) {
            this.renderer.setProperty(this.ePlaceholder, 'innerHTML', '');
            this.renderer.listen(this.ePlaceholder, 'animationend', () => {
                this.renderer.removeChild(this.el.nativeElement, this.ePlaceholder);
                this.spawned = false;
                this.ePlaceholder = null;
            });
            this.renderer.setAttribute(this.ePlaceholder, 'class', 'placeholder loaded');
        }
    }

    spawn() {
        if (!this.spawned) {
            this.ePlaceholder = this.renderer.createElement('div');
            this.renderer.setAttribute(this.ePlaceholder, 'class', 'placeholder');
            this.renderer.setProperty(this.ePlaceholder, 'innerHTML', 'Placeholder');
            this.renderer.appendChild(this.el.nativeElement, this.ePlaceholder);
            this.spawned = true;
        }
    }

    changeColor() {
        this.renderer.addClass(this.ePlaceholder, `color-${this.aixPlaceholderConfig().color}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aixPlaceholderConfig && changes.aixPlaceholderConfig.firstChange) {
            // Loading
            this.isLoading$ = this.aixPlaceholderConfig().state$.pipe(
                map(states => states.some(state => !isSuccess(state)))
            );
            this.subscriptions.push(
                this.isLoading$.subscribe((val: boolean) => {
                    if (!val) {
                        this.despawn();
                    }
                })
            );

            // Color
            if (this.aixPlaceholderConfig().color) {
                this.changeColor();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
