<div class="investor__element" [data-testing]="'signer-element'">
    <div class="investor__info flex-between-top">
        <div class="investor__name" [data-testing]="'name'">
            <p [data-testing]="'recipient-name'">{{ recipient()?.fullName }}</p>
            <p [data-testing]="'recipient-email'">{{ recipient()?.email }}</p>
            @if (recipient()?.type !== 'advisor' && recipient()?.phoneMobile) {
            <p>Mobile: {{ recipient()?.phoneMobile | aixPhone }}</p>
            }
        </div>

        @if (showSending() && !recipient()?.status && (recipient()?.routingOrder ?? 0) === 1) {
        <div class="u-pl24 signature__status">
            <p class="u-green u-text-right">
                <span class="fa-regular fa-paper-plane u-mr8"></span>Sending
            </p>
        </div>
        } @if (showSending() && !recipient()?.status && (recipient()?.routingOrder ?? 0) > 1) {
        <p class="u-color-tertiary-black">Not yet sent.</p>
        } @if (recipient()?.status) {
        <div class="u-pl24 signature__status">
            @if (recipient()?.status === recipientStatus.created) {
            <p class="u-color-tertiary-black" [data-testing]="'created-status'">Not yet sent.</p>
            } @if (recipient()?.status === recipientStatus.sent) {
            <div [data-testing]="'sent-status'">
                <p class="u-green u-text-right">
                    <span class="fa-regular fa-paper-plane u-mr8"></span>Sent
                </p>
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    @if (recipientTimestamp) {
                    <span>
                        {{ recipientTimestamp }}
                    </span>
                    }
                </p>
            </div>
            } @if (recipient()?.status === recipientStatus.delivered) {
            <div [data-testing]="'delivered-status'">
                <p class="u-green u-text-right">
                    <span class="fa-regular fa-file-signature u-mr8"></span>Viewed
                </p>
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    @if (recipientTimestamp) {
                    <span>
                        {{ recipientTimestamp }}
                    </span>
                    }
                </p>
            </div>
            } @if (recipient()?.status === recipientStatus.completed) {
            <div [data-testing]="'signed-status'">
                <p class="u-green u-text-right">
                    <span class="fa-regular fa-check u-mr8"></span>Signed
                </p>
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    @if (recipientTimestamp) {
                    <span>
                        {{ recipientTimestamp }}
                    </span>
                    }
                </p>
            </div>
            } @if (recipient()?.status === recipientStatus.declined) {
            <div [data-testing]="'declined-status'">
                <p class="u-warning-orange u-text-right">
                    <span class="fa-regular fa-circle-exclamation u-mr8"></span>Signature Declined
                </p>
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    @if (recipientTimestamp) {
                    <span>
                        {{ recipientTimestamp }}
                    </span>
                    }
                </p>
            </div>
            } @if (recipient()?.status === recipientStatus.autoResponded) {
            <div [data-testing]="'undeliverable-status'">
                <p class="u-error-red u-text-right">
                    <span class="fa-regular fa-triangle-exclamation u-mr8"></span>Email
                    Undeliverable
                </p>
                <p class="u-font-size--12 u-color-tertiary-black u-text-right">
                    @if (recipientTimestamp) {
                    <span>
                        {{ recipientTimestamp }}
                    </span>
                    }
                </p>
            </div>
            }
        </div>
        }
    </div>
</div>
