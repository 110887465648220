@if (arrayFiles.length > 0) {
<div class="flex-left-center" [data-testing]="'form-filelist-header'">
    <h5>Required Forms</h5>
</div>
} @for (item of arrayFiles; track item) {
<div class="flex-col" [data-testing]="'form-filelist-row'">
    <div class="order-step-form-row">
        <div class="order-step-form-row-container">
            @if (item.form) {
            <div>
                @if (item.showAsLink || item.showAsSpan) {
                <p class="order-step-form-row-title" [data-testing]="'title'">
                    @if (item.showAsLink) {
                    <a class="br-link u-no-underline selectable" (click)="viewEvent(item)"
                        >{{ item.form.name }}</a
                    >
                    } @if (item.showAsSpan) {
                    <span>{{ item.form.name }}</span>
                    } @if (item.form.isOnboarded && item.form.noSignatures) {
                    <span class="form-filelist-icon" [aixTooltipContent]="noSignatures" aixTooltip>
                    </span>
                    }
                </p>
                } @if ((item.showAsLink || item.showAsSpan) && !hideFormDocumentOwner) {
                <p class="order-step-form-row-subtitle" [data-testing]="'firm'">
                    <span
                        >{{ item.form.documentOwner === 'Alternative Investment Exchange' ? 'Your
                        Firm' : item.form.documentOwner }}</span
                    >
                </p>
                }
            </div>
            } @if (!item.form) {
            <div>
                <p class="order-step-form-row-title">
                    <a class="br-link" (click)="onEdit.emit()">Label uploaded document</a>
                </p>
            </div>
            }
        </div>

        @if (!(store.orderRemoteData$ | async | isInProgress) && !item.isOpen) {
        <div>
            @if (!isFileUploaded(item) && (!item.form?.isOnboarded || (item.form?.isOnboarded &&
            !item.form?.noSignatures))) {
            <div class="order-step-form-row__actions">
                @if (!isFormNotOnboarded(item) && !item.isUploading) {
                <aix-loading-button
                    [data-testing]="'download-button'"
                    [buttonStyle]="'tertiary'"
                    [label]="'Download'"
                    [icon]="'fa-regular fa-download'"
                    [isLoading]="store.documentViewer.downloading[removeFileExtension(item.file?.name ?? '')]"
                    (click)="onClickDownload(item)"
                    style="margin-top: 1px; padding-top: 2px; margin-right: 12px"
                >
                </aix-loading-button>
                } @if (isFormNotOnboarded(item)) {
                <span
                    class="form-filelist-icon"
                    [ngClass]="{ 'u-mr16': !hasMaxFormUploads }"
                    [aixTooltipContent]="notOnboardedTooltipText"
                    aixTooltip
                >
                </span>
                } @if (!hasMaxFormUploads) {
                <aix-button
                    [isDisabled]="isReadOnly()"
                    [buttonType]="uploadButtonType"
                    [buttonLabel]="'Upload'"
                    [icon]="'fa-upload'"
                    [data-testing]="'upload-button'"
                    style="padding-top: 2px"
                    (click)="uploadEvent(item)"
                >
                </aix-button>
                }
            </div>
            } @if (isFileUploaded(item) && !item.isUploading) {
            <div class="order-step-form-row__actions">
                <div class="order-step-action-items order-step-action-items__remove">
                    <aix-inline-dropdown
                        [dropdownOptions]="isFileNotOnboarded(item) ? ['View', 'Relabel', 'Remove'] : ['View', 'Replace', 'Remove']"
                        [label]="'Manage'"
                        (optionSelected)="onOptionSelected($event, item)"
                        [disabledOptions]="isDisabledFile(item.file)"
                    >
                    </aix-inline-dropdown>
                </div>
            </div>
            }
        </div>
        }
    </div>

    @if (item.form && showRelabelDialog(item.form)) {
    <div class="file-upload-container">
        <div>
            <div class="flex-between-center u-mb16">
                <span class="u-font-size--16 u-fw500">Label Forms</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="relabelForm = null;"
                >
                </span>
            </div>
            <aix-label-uploaded-form-documents
                class="aix-form__container u-block"
                (filesUpdated)="aixFileUpload().onRelationsSaved()"
                [relabelForm]="relabelForm"
                (dismissModal)="relabelForm = null;"
            >
            </aix-label-uploaded-form-documents>
        </div>
    </div>
    } @if (item.form && showMissingLabel(item.form)) {
    <div class="missing-label-container">
        <aix-tag
            class="missing-label-badge"
            [color]="'warn'"
            [icon]="'far fa-exclamation-triangle'"
            [label]="'Document Missing Label'"
            [labelColor]="'#263238'"
        ></aix-tag>
    </div>
    } @if (item.isOpen) {
    <div class="file-upload-container" [data-testing]="'file-upload'">
        <div class="flex-between-center">
            <span class="u-font-size--16 u-fw500" [data-testing]="'upload-title'">Upload</span>
            <span
                class="fa-regular fa-xmark u-color-tertiary-black"
                [data-testing]="'close-upload-button'"
                (click)="item.isOpen = false"
            >
            </span>
        </div>
        <div class="u-mt16">
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="fileType.form"
                [formId]="item.form?.id"
                [isModal]="true"
                [fileSizeLimit]="fileSizeLimit"
                (filesSelected)="onFilesSelected(item)"
                (filesUploaded)="onFilesUploaded(item)"
            >
            </aix-upload-document>
        </div>
    </div>
    }
</div>
} @if (currentStep === 4) {
<div class="order-step-form-row no-border">
    <div class="order-step-form-row-container">
        <span class="order-step-form-row-title">
            Do you have other forms that need to be signed?
        </span>
        <span
            class="formlist-icon u-pt4"
            [aixTooltipContent]="'Upload a completed, signed copy of the required form.'"
            aixTooltip
        >
        </span>
    </div>
    <div class="order-step-form-row__actions">
        <span class="order-step-action-items br-primary__color">
            <aix-button
                [buttonType]="uploadButtonType"
                buttonLabel="Upload Forms"
                [icon]="'fa-upload'"
                class="u-pl16 selectable"
                [isDisabled]="aixFileUpload.fileUploadOpen"
                (click)="aixFileUpload.onUploadDocuments()"
            >
            </aix-button>
        </span>
    </div>
</div>
<aix-file-upload #aixFileUpload [type]="fileType.form"> </aix-file-upload>
}
