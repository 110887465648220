import { Editor, NgxEditorModule, toHTML, Toolbar } from 'ngx-editor';
import {
    Component,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'aix-rich-text',
    templateUrl: './aix-rich-text.component.html',
    styleUrls: ['./aix-rich-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixRichTextComponent)
        }
    ],
    standalone: true,
    imports: [NgxEditorModule, FormsModule]
})
export class AixRichTextComponent implements OnInit, OnChanges, OnDestroy {
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    showLabel = input<boolean>(true);
    config = model<any>();
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = model<boolean>(false);
    initialValue = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    editor: Editor;
    html = '';
    value = '';

    private isDirty = false;

    @HostBinding('attr.aix-control')
    aixControl: string;

    toolbar: Toolbar = [
        // ['bold', 'italic'],
        // ['underline', 'strike'],
        // ['code'],
        ['ordered_list', 'bullet_list']
        // ['link', 'image'],
        // ['text_color', 'background_color'],
        // ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    constructor() {}

    ngOnInit(): void {
        this.editor = new Editor();

        this.editor.valueChanges.subscribe(r => {
            if (!this.isDirty) {
                this.setDirty.emit();
                this.isDirty = true;
            }
            const html = toHTML(r);
            this.value = html.replace(/(<([^>]+)>)/gi, '');
            this.valueChanges.emit(html);
            this._propagateChanges(html);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            this.html = this.initialValue();
        }
    }

    writeValue(value: any) {}

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }

    ngOnDestroy(): void {
        // make sure to destroy the editor
        this.editor.destroy();
    }
}
