<div [ngClass]="{ resolved: resolved }" class="order-form-comments__comment-text">
    {{ comment()?.text }}
</div>
<label class="checkbox-custom checkbox-custom-label-size order-form-comments__comment-text u-mt8">
    <input
        [(ngModel)]="resolved"
        (change)="onCheckChange(resolved)"
        class="order-form-comments__comment-input"
        type="checkbox"
    />
    <span class="checkbox-custom__indicator"></span>
    <span class="checkbox-custom__label checkbox-custom__label--xl">Completed</span>
</label>
