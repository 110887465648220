<div [hidden]="!canAddComments() || isDocusignCertificate || mode() === 'resolve'">
    <textarea
        class="order-form-comments__input order-form-comments__input__text-area u-mt8 u-mb16"
        placeholder="Add a comment"
        [data-testing]="'comment-text-area'"
        [(ngModel)]="commentText"
    >
    </textarea>

    <aix-order-form-comment-tags
        (commentTagChange)="commentTagChange($event)"
        (commentPrivateChange)="commentPrivateChange($event)"
        [(commentTag)]="commentTag"
        [isPrivate]="isPrivate"
    ></aix-order-form-comment-tags>

    <div class="flex-right-center u-mt24 u-mb24">
        <aix-button
            [data-testing]="'add-button'"
            (click)="doCommentAction('create')"
            [isDisabled]="commentText.trim().length === 0 || !commentTag"
            class="aix-btn--primary-big"
            [buttonType]="addButtonType"
            [buttonLabel]="'Add'"
        >
        </aix-button>
    </div>
    <hr class="order-form-comments__separator" />
</div>

@if (isDocusignCertificate && canAddComments()) {
<span>
    <aix-notification status="warning" [canClose]="false" [isOpen]="true">
        Comments cannot be added to system generated documents.
    </aix-notification>
    @if (comments().length > 0) {
    <hr class="u-mt24" />
    }
</span>
} @if (comments()?.length === 0 && mode() === 'modify') {
<div class="u-text-center u-mt24">
    <i
        [hidden]="canAddComments() && isDocusignCertificate"
        class="fa-regular fa-messages u-font-size--24 br-primary__color"
    ></i>

    @if (canAddComments() && !isDocusignCertificate) {
    <span>
        <h5 [data-testing]="'cta-comments-title'">Have comments to add?</h5>
        <p [data-testing]="'cta-comments-message'">
            Add comments if this order needs changes, clarification, or notes.
        </p>
    </span>
    } @if (!canAddComments()) {
    <h5 [data-testing]="'no-comments-message'">There are no comments for this order</h5>
    }
</div>
}
<aix-order-form-comments-list
    [data-testing]="'comments-list'"
    [comments]="comments()"
    [showSystemUpdates]="false"
    [commentRef]="commentRef"
    [mode]="mode()"
>
    <ng-template #commentRef let-comment>
        @if (mode() === 'modify') {
        <aix-order-form-comment
            [comment]="comment"
            [canReply]="canReply()"
            (onCommentAction)="onCommentAction($event)"
        >
        </aix-order-form-comment>
        } @if (mode() === 'resolve') {
        <aix-order-form-resolve-comment [comment]="comment" [parentForm]="parentForm()">
        </aix-order-form-resolve-comment>
        }
    </ng-template>
</aix-order-form-comments-list>
